import React, { useState, useEffect, lazy, Suspense } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { Sponsor, Member, RegisterPreviewPage, RegisterConfirmationPage, TravelAssistance, Donate, InternationEventDetailPage, InternationEventsList, MembershipPreviewPage, MembershipConfirmationPage, DonationPreviewPage, DonationConfirmationPage, TravelAssistancePreviewPage, PreviewConcert, BookingConfirmationPage, News, NewsList, NewsDetailPage } from "../Pages";
import { Register } from "../Pages/Register/Register";
import { Api } from "../Constants";
import { InternationalEvents } from "../Common";
import { Login } from "../Pages/Login";
import { ForgetPassword } from "../Pages/ForgetPassword";
import ClipLoader from 'react-spinners/ClipLoader';

const HomePage = lazy(() => import('../Pages/Homepage/Homepage'));
const StaticPage = lazy(() => import('../Pages/StaticPage/StaticPage'));
const ContactUs = lazy(() => import('../Pages/ContactUs/ContactUs'));
const BookConcert = lazy(() => import('../Pages/BookConcert/BookConcert'));
const TeachingPage = lazy(() => import('../Pages/StaticPage/TeachingPage'));

const defaultLinks = {
    facebook: "https://www.facebook.com/televisionsdharma",
    linkedin: 'https://www.linkedin.com/in/dharma-television-7642b4237/',
    youtube: 'https://www.youtube.com/@dharmatelevision7477',
    mail: "info@dharmatelevision.tv",
    site_logo: "",
    // twitter: 'https://twitter.com/dharmatelevision'
}

const officialContacts = {
    headOffice: "",
    email: "",
    tel: "",
    mobile_no: "",
    branchOffice: "",
    branch_mobile_no: "",
    organization_name: "",
}

const AppRouter = () => {

    const [links, setLinks] = useState(defaultLinks);
    const [contacts, setContacts] = useState(officialContacts);
    const [footerDescription, setFooterDescription] = useState("");

    const handleLinks = async () => {
        const data = await Api.getSiteSetting();
        if (!data) return;
        const settings = data.site_setting.pop();
        if (!settings) return;
        const { youtube, facebook, mail, linkedin, head_office, email, tel, phone_no, branch_office, branch_office_no, organization_name, site_logo} = settings;
        setLinks({ ...defaultLinks, youtube, facebook, mail, linkedin,  site_logo});
        setContacts({ ...officialContacts, headOffice: head_office, email, tel, mobile_no: phone_no, branchOffice: branch_office, branch_mobile_no: branch_office_no, organization_name: organization_name });
        setFooterDescription(settings.footer_about);
    }

    useEffect(() => {
        handleLinks();
        window.scrollTo(0, 0);
    }, []);

    return (
        <BrowserRouter basename="/" forceRefresh={true}>
            <Suspense fallback={
                <div className="startingLoader text-center">
                    <ClipLoader color="#970d0d" />
                    {/* <Spinner animation='border' /> */}
                </div>
            }>

                <Routes>
                    <Route path="/" element={<HomePage links={links} contacts={contacts} footerDescription={footerDescription} />} />

                    <Route path="/register" element={<Register links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    {/* <Route path="/preview-page/" element={<RegisterPreviewPage links={links} contacts={contacts} footerDescription={footerDescription} />} /> */}
                    {/* <Route path="/register-confirmation/" element={<RegisterConfirmationPage links={links} contacts={contacts} footerDescription={footerDescription} />} /> */}
                    {/* <Route path="/edit-registration/" element={<Register links={links} contacts={contacts} footerDescription={footerDescription} />} /> */}
                    <Route path="/preview-page/:id" element={<RegisterPreviewPage links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/register-confirmation/:id" element={<RegisterConfirmationPage links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/edit-registration/:id" element={<Register links={links} contacts={contacts} footerDescription={footerDescription} />} />

                    <Route path="/book-concert" element={<BookConcert links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/edit-booking/:id" element={<BookConcert links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/preview-booking/:id" element={<PreviewConcert links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/booking-confirmation/:id" element={<BookingConfirmationPage links={links} contacts={contacts} footerDescription={footerDescription} />} />



                    <Route path="/contact-us" element={<ContactUs links={links} contacts={contacts} footerDescription={footerDescription} />} />

                    <Route path="/member" element={<Member links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/preview-membership-page/" element={<MembershipPreviewPage links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/membership-confirmation/" element={<MembershipConfirmationPage links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/edit-membership/" element={<Member links={links} contacts={contacts} footerDescription={footerDescription} />} />

                    <Route path="/sponsor" element={<Sponsor links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/donate-now/" element={<Donate links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/preview-donation/" element={<DonationPreviewPage links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/edit-donation/" element={<Donate links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/donation-confirmation/" element={<DonationConfirmationPage links={links} contacts={contacts} footerDescription={footerDescription} />} />

                    <Route path="/travel-assistance/" element={<TravelAssistance links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/preview-travel-details/" element={<TravelAssistancePreviewPage links={links} contacts={contacts} footerDescription={footerDescription} />} />


                    <Route path="/international-events/" element={<InternationEventsList links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    {/* <Route path="/international-events/" element={<InternationalEvents links={links} contacts={contacts} footerDescription={footerDescription} />} /> */}
                    <Route path="/news" element={<NewsList links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/news/:slug" element={<NewsDetailPage links={links} contacts={contacts} footerDescription={footerDescription} />} />


                    <Route path="/teaching" element={<TeachingPage links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/:slug" element={<StaticPage links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/login" element={<Login links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="/forget_password" element={<ForgetPassword links={links} contacts={contacts} footerDescription={footerDescription} />} />
                    <Route path="*" element={<Navigate to="/" />} />


                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}

export default AppRouter;