import React, { useState, useEffect } from "react";
import { FaRegUser } from "react-icons/fa";
import { FaComments } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import { CgFormatSlash } from "react-icons/cg";
import { AiFillPlayCircle } from "react-icons/ai";

import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import './News.css'
import { Images, Api, HelperFunction } from "../../Constants";
import { Link } from "react-router-dom";

const News = () => {

    const [news, setNews] = useState([]);

    useEffect(() => {
        getNews();
    }, []);


    const getNews = async (id) => {
        const res = await Api.getNewsData(id);
        if (res.status) {
            console.log(res)
            setNews(res.data);
        } else {
            setNews()
        }
    }


    const RenderChild = (props) => {
        const { slug: slug, file_image: file_image, title: event_name, description: description } = props.data;
        return (
            <div className="news-events-captions">
                <Link to={`/news/${slug}`} state={props.data} className="text-decoration-none ">
                    <img
                        src={`${HelperFunction.FILE_URL}/pdf_image/${file_image}`}
                        alt="dharma" className="img-fluid w-100 i-links" style={{ backgroundColor: '#970d0d' }} />
                </Link>
                <div className="events-label my-3 mx-3">
                    {/* <div className="events-label my-3 mt-4 mx-3"> */}
                    {/* <div className="row mb-2">
                        <div className="col">
                            <div className="d-flex justify-content-start align-items-center">
                                <FaRegUser color="#970d0d" />
                                <div className="mx-2">Admin</div>
                                <FaComments color="#970d0d" className="ms-3" />
                                <div className="mx-2">Comments</div>
                            </div>
                        </div>
                     
                    </div> */}
                    <div className="row">
                        <Link to={`/news/${slug}`} state={props.data} className="text-decoration-none i-links">

                            <div className="event-name text-decoration-none i-links my-1 ">
                                {event_name}
                            </div>
                        </Link>
                    </div>
                </div>

                <hr />
                <div className="more-section mx-3 pb-3">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex justify-content-start align-items-center">
                                <div className="read-more">Read More</div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-end align-items-center me-lg-2">
                                <Link to={`/news/${slug}`} state={props.data} className="text-decoration-none text-dark i-links">
                                    <div><BsArrowRight className="arrow i-links" color="#970d0d" /></div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            {news ? (
                <>
                    <div className="container">
                        <div className="mx-3 mb-xl-2 py-3">
                            <div className="internationalEvents-header text-center text-lg-start">
                                <div className="mt-1 d-flex align-items-end justify-content-center justify-content-lg-start"><CgFormatSlash color="#970d0d" className="slash" style={{ marginLeft: '-8px' }} /><CgFormatSlash color="#970d0d" className="slash" style={{ marginLeft: '-26px' }} /><CgFormatSlash color="#970d0d" className="slash" style={{ marginLeft: '-26px' }} /><span>News</span></div>
                                <h1 className="internationalEvents-title text-capitalize my-4">News</h1>
                            </div>
                            <div>

                                <div className='row'>

                                    {news.map((elem, index) => (
                                        <div key={index} className='col-12 col-md-6 col-xl-4 mb-4 mb-lg-5'>
                                            <RenderChild key={elem.id} data={elem} />
                                        </div>
                                    )
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="icon4">
                        <img src={Images.icon4} alt="Dharma Idol Logo" className="img-fluid" />
                    </div>
                </>
            ) : (
                <div className="aboutus">
                    <div className="container">
                        <div className="py-4 mx-lg-2">
                            <div className='page-not-found my-5 py-5 text-center text-black'> Coming Soon...
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default News;
