import { React } from "react";
import { Header, Footer, News } from "../../Common";


const NewsList = (props) => {
    return (
        <>
            <Header links={props.links} />
            <div className="aboutus projects-section" >
                <News />
                <div className="pb-2 pb-lg-5"></div>
            </div>
            <Footer links={props.links} contacts={props.contacts} footerDescription={props.footerDescription} />

        </>
    )
};

export default NewsList;