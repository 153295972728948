import React, { useEffect, useState } from "react";
import { Header, Footer } from "../../Common";
import {} from "react-router-dom";
import { Api, Images } from "../../Constants";
// import './RegisterPreviewPage.css';
import { Button } from "react-bootstrap";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
// import { QRCodeSVG } from "qrcode.react";
const PreviewConcert = (props) => {
  const location = useLocation();

  const [slug, setSlug] = useState(null);
  const [participantsData, setParticipantData] = useState();
  const pathName = useParams();
  const navigate = useNavigate();

  // useEffect(() => {
  //     const id = location.state && location.state.id;
  //     window.scrollTo(0,0);
  //     setSlug(id)
  //     getPreviewData(id);
  // }, [location.state]);
  useEffect(() => {
    const id = pathName.id;
    window.scrollTo(0, 0);
    setSlug(id);
    getPreviewData(id);
  }, [pathName.id]);

  const getPreviewData = async (id) => {
    const res = await Api.getParticipantDataFromUserId(id);
    if (res.status) {
      console.log(res);
      setParticipantData(res.participant_detail);
      console.log("res", res.participant_detail.participant_detail);
      // setParticipantData(stateOptions)
    } else {
      // setParticipants({})
    }
  };

  const sendEmail = async (id) => {
    const res = await Api.sendEmail(id);
    if (res) {
      navigate(
        `/booking-confirmation/${id}?name=${participantsData.event_details[0].name}`
      );

      // const activateRes = await Api.sendEmailtoActivateEventRegistrants(id);
      // if (activateRes) {
      //     // navigate(`/register-confirmation/`, { state: { id: id }} );

      // }
    } else {
      // toast.error("Something went wrong. Please try again!", {
      //     position: toast.POSITION.TOP_RIGHT,
      // });
    }
  };

  return (
    <>
      <Header links={props.links} />
      <div className="booking-div">
        <div className="container">
          <div className="row mx-0">
            <div className="col-lg-12 py-3 mx-lg-2">
              <h1 className="register-title pt-4 fs-3 label-font">
                Please Confirm Your Registration Details
              </h1>

              <div className="row">
                <div className="col-12 col-md-6 col-xl-5 mt-3 form-group">
                  <label className="form-label col pl-0">
                    Programme to Book a Ticket*
                  </label>
                  {participantsData?.event_details?.map((elem) => (
                    <div
                      key={elem.id}
                      className="preview-data text-capitalize px-3"
                    >
                      {elem.venue} - {elem.name} (
                      {moment(elem.date_from).format("MMM DD, YYYY")})
                    </div>
                  ))}
                </div>
                <div className="col-12 col-md-6 col-xl-3 mt-3 form-group">
                  <label className="form-label col pl-0">
                    <span>Category</span>
                  </label>
                  <div className="preview-data text-capitalize px-3">
                    {participantsData?.event_category}
                  </div>
                </div>

                <div className="row me-0 pe-0">
                  <div className="col-12 col-md-6 col-xl-4 mt-3 form-group pe-1 pe-md-2">
                    <label className="form-label col pl-0">
                      <span>No. of tickets *</span>
                    </label>
                    <div className="preview-data text-capitalize px-3">
                      {participantsData?.number_of_tickets}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-4 mt-3 form-group pe-1 pe-md-2">
                    <label className="form-label col pl-0">
                      <span>Amount *</span>
                    </label>
                    <div className="preview-data text-capitalize px-3">
                      {participantsData?.amount ? "₹" : ""}{" "}
                      {participantsData?.amount}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-xl-8 px-0">
                  <div className="row mx-0">
                    <div className="col-12 col-md-6 col-xxl-4 mt-3">
                      <label className="form-label col pl-0">
                        <span>Full Name *</span>
                      </label>
                      <div className="preview-data text-capitalize">
                        {participantsData?.participant_detail?.full_name}
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-xxl-4 form-group mt-3">
                      <label className="form-label col pl-0">
                        <span>Email *</span>
                      </label>
                      <div className="preview-data">
                        {participantsData?.participant_detail?.email}
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-xxl-4 mt-3">
                      <label className="required form-label col pl-0">
                        <span>Phone *</span>
                      </label>
                      <div className="preview-data">
                        {participantsData?.participant_detail?.phone}
                      </div>
                    </div>

                    <div className="col-12 participant-f my-3">
                      <label className="label-font col pl-0">
                        <span>Address</span>
                      </label>
                      <div className="row mx-0">
                        <div className="col-12 col-md-6 col-xl-4 px-0 pe-md-3">
                          <span className="ms-0 ps-0 addressLabel">
                            Country
                          </span>
                          <div className="preview-data text-capitalize">
                            {participantsData?.participant_detail?.country_name
                              ? participantsData?.participant_detail
                                  ?.country_name
                              : "-"}
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4 px-0 mt-3 mt-md-0">
                          <span className="ms-0 ps-0 addressLabel">State</span>
                          <div className="preview-data text-capitalize">
                            {participantsData?.participant_detail?.state_name
                              ? participantsData?.participant_detail?.state_name
                              : "-"}
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4 px-0 pe-md-3 ps-xl-3 pe-xl-0 mt-3 mt-xl-0">
                          <span className="ms-0 ps-0 addressLabel">City</span>
                          <div className="preview-data">
                            {participantsData?.participant_detail?.city_new_name
                              ? participantsData?.participant_detail
                                  ?.city_new_name
                              : "-"}
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4 px-0 pe-md-3 mt-3">
                          <span className="ms-0 ps-0 addressLabel">Street</span>
                          <div className="preview-data text-capitalize">
                            {participantsData?.participant_detail?.street}
                          </div>
                        </div>

                        {/* <div className="col-12 col-md-6 col-xl-4 px-0 mt-3 pe-md-3">
                                                    <span className="ms-0 ps-0 addressLabel">Zip Code</span>
                                                    <div className="preview-data text-capitalize">{participantsData?.participant_detail?.zip_code ? participantsData?.participant_detail?.zip_code : '-'}</div>
                                                </div> */}
                      </div>
                    </div>

                    <div className="col-12 my-3">
                      <label className="label-font col pl-0">
                        <span>
                          Please make a payment of amount{" "}
                          {participantsData?.amount ? "₹" : ""}{" "}
                          {participantsData?.amount} to any of the following QR
                          code and send screenshot of a confirmation with
                          Subject: Payment Confirmation to web@btmcfoundation.in
                        </span>
                      </label>
                    </div>
                    {/* <QRCodeSVG value="#" className="my-3" /> */}
                    <div className="d-flex justify-content-center ">
                      <div className="d-flex gap-3">
                        <div className="d-flex flex-column justify-content-center">
                          <div className="d-flex justify-content-center mb-2">
                            <label className="label-font">PayTM</label>
                          </div>
                          <img src={Images.qr1} width={150} height={150} />
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <div className="d-flex justify-content-center mb-2">
                            <label className="label-font">PhonePe</label>
                          </div>
                          <img src={Images.qr2} width={150} height={150} />
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <div className="d-flex justify-content-center mb-2">
                            <label className="label-font">
                              HDFC Bank - 0862
                            </label>
                          </div>
                          <img src={Images.qr3} width={150} height={150} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row d-flex justify-content-center justify-content-lg-end mt-3">
                                <Button as = {Link} to={`/edit-registration/${slug}`} className="send-btn btn-warning col-5 col-lg-2 my-2 py-2 mx-1" type="button">
                                    Edit
                                </Button>
                                <Button as = {Link} to={`/register-confirmation/${slug}`} className="contact-btn send-btn col-5 col-lg-2 my-2 py-2 mx-1" type="button">
                                    Confirm
                                </Button>
                            </div> */}
              {/* <Button as={Link}
                                    to = '/edit-registration/'
                                    state= {{ id: slug }}
                                    className="send-btn btn-warning col-5 col-lg-2 my-2 py-2 mx-1" type="button">
                                    Edit
                                </Button> */}
              <div className="col-12 col-xl-8">
                <div className="d-flex justify-content-center justify-content-lg-end mt-3 px-2">
                  <Button
                    as={Link}
                    to={`/edit-booking/${slug}`}
                    className="send-btn btn-warning col-5 col-lg-2 my-2 py-2 mx-1"
                    type="button"
                  >
                    Edit
                  </Button>

                  <Button
                    className="contact-btn send-btn col-5 col-lg-2 my-2 py-2 mx-1"
                    type="button"
                    onClick={() => sendEmail(slug)}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        links={props.links}
        contacts={props.contacts}
        footerDescription={props.footerDescription}
      />
    </>
  );
};

export default PreviewConcert;
