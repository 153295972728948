import React, { useState, useEffect } from "react";
import { Header, Footer } from "../../Common";
import { Api, HelperFunction, Images } from "../../Constants";
import ClipLoader from 'react-spinners/ClipLoader';
import { useParams } from "react-router-dom";

import './StaticPage.css';

const TeachingPage = (props) => {

    const [data, setData] = useState({});
    const [slug, setSlug] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const pathName = useParams();

    useEffect(() => {
        setSlug(pathName.slug)
        getData();
    }, []);

    const getData = async (slug) => {
        setIsLoading(true);
        const res = await Api.getStaticPage(`/page/teaching`);
        if (res) {
            console.log('res', res)
            setData(res.page);
        } else {
            console.log('data', data)

            setData();
        }
        setIsLoading(false);
    }

    return (

        <>
            <Header links={props.links} />
            {data ? (
                <div className="aboutus">
                    <div className="container">
                        <div className="py-4 mx-lg-2">
                            {isLoading ? (
                                <div className='my-spinner text-center'>
                                    {/* <Spinner animation='border' /> */}
                                    <ClipLoader color="#970d0d" />
                                </div>
                            ) : (
                                <div className="row form-group mx-0">
                                    <h1 className="about-title py-4 ps-0 pe-lg-5 fs-3 text-uppercase">
                                        {data?.name}
                                    </h1>
                                    <div className="col ps-0">
                                        <div dangerouslySetInnerHTML={{ __html: data?.description }} className='static-content' style={{ textAlign: "justify" }} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (

                <div className='page-not-found my-5 py-5 text-center'> Coming Soon...
                </div>

            )}
            <Footer links={props.links} contacts={props.contacts} footerDescription={props.footerDescription} />

        </>
    );
}

export default TeachingPage;