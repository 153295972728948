import React, { useState, useEffect } from "react";
import { Header, Footer } from "../../Common";
import { Api, HelperFunction, Images } from "../../Constants";
import { Spinner } from 'react-bootstrap'
import { useParams } from "react-router-dom";
import ClipLoader from 'react-spinners/ClipLoader';


import './StaticPage.css';


const StaticPage = (props) => {

    const [data, setData] = useState({});
    const [slug, setSlug] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const pathName = useParams();

    useEffect(() => {
        setSlug(pathName.slug)
        getData(pathName.slug);
    }, [pathName.slug]);

    const getData = async (slug) => {
        setIsLoading(true);
        console.log('slug', slug)
        const res = await Api.getStaticPage(`/page/${pathName.slug}`);
        if (res) {
            console.log('res', res)
            setData(res.page);
        } else {
            console.log('data', data)

            setData();
        }
        setIsLoading(false);
    }

    return (

        <>
            <Header links={props.links} />
            <div className="aboutus">
                <div className="container">
                    <div className="py-4 mx-lg-2">
                        {isLoading ? (
                            <div className='my-spinner text-center'>
                                {/* <Spinner animation='border' /> */}
                                    <ClipLoader color="#970d0d" />
                            </div>
                        ) : (
                            <>
                                {data ? (

                                    <div className="row form-group mx-0">
                                        <h1 className="about-title py-4 ps-0 pe-lg-5 fs-3 text-uppercase">
                                            {/* {slug === "about-dharma-ideal-campaign" ? 'About Dharma Ideal Campaign' : slug === 'about-venerable-khem-sonam-rinpoche' ? 'About Ven. Khem Sonam Rinpoche' : slug === 'dharma-ideal-campaign' ? 'Dharma Ideal Campaign' : slug === 'latest-news' ? 'Latest News' : slug === 'dharma-projects' ? 'Dharma Projects' : slug === 'schedule' ? 'Schedule' : slug === 'international-events' ? 'International Events' : slug === 'contact-us' ? 'Contact Us' : data?.name} */}
                                            {data?.name}
                                        </h1>
                                        <div className="col-lg-8 ps-0 pe-lg-5">
                                            <div dangerouslySetInnerHTML={{ __html: data?.description }} className='static-content' style={{ textAlign: "justify" }} />
                                        </div>
                                        <div className=" col-lg-4">
                                            {data.main_image !== null ? (
                                                <img
                                                    src={`${HelperFunction.FILE_URL}/main_image/${data.main_image}`}
                                                    alt="btmc" className="img-fluid w-100" style={{ backgroundColor: slug === "about-venerable-khem-sonam-rinpoche" || slug === "welcome-message"? '#970d0d' : 'transparent' }} />
                                            ) : (
                                                <img src={Images.dharmalogo1} alt="dharma" className="img-fluid w-100" />
                                            )}
                                        </div>
                                    </div>

                                ) : (

                                    <div className='page-not-found my-5 py-5 text-center'> Coming Soon...
                                    </div>

                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Footer links={props.links} contacts={props.contacts} footerDescription={props.footerDescription} />

        </>
    );
}

export default StaticPage;