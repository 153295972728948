import React, { useEffect, useState } from "react";
import { Header, Footer } from "../../Common";
import { } from "react-router-dom";
import { Api } from "../../Constants";
import './RegisterPreviewPage.css';
import { Button } from "react-bootstrap";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";

const RegisterPreviewPage = (props) => {
    const location = useLocation();

    const [slug, setSlug] = useState(null);
    const [participantsData, setParticipantData] = useState();
    const pathName = useParams();
    const navigate = useNavigate();

    // useEffect(() => {
    //     const id = location.state && location.state.id;
    //     window.scrollTo(0,0);
    //     setSlug(id)
    //     getPreviewData(id);
    // }, [location.state]);
    useEffect(() => {
        const id = pathName.id;
        window.scrollTo(0,0);
        setSlug(id)
        getPreviewData(id);
    }, [pathName.id]);

    const getPreviewData = async (id) => {
        const res = await Api.getParticiapantDataFromRegistrationId(id);
        if (res.status) {
            console.log(res)
            setParticipantData(res.participant_detail);
            console.log('res', res.participant_detail.participant_detail)
            // setParticipantData(stateOptions)
        } else {
            // setParticipants({})
        }
    }

    const sendEmail = async (id) => {
        const res = await Api.sendEmail(id);
        if (res) {
            const activateRes = await Api. sendEmailtoActivateEventRegistrants(id);
            if (activateRes) {
                // navigate(`/register-confirmation/`, { state: { id: id }} );
                navigate(`/register-confirmation/${id}`);


            }
        } else {
            // toast.error("Something went wrong. Please try again!", {
            //     position: toast.POSITION.TOP_RIGHT,
            // });
        }


    }


    return (
        <>
            <Header links={props.links} />
            <div className="register">
                <div className="container">
                    <div className="row mx-0">
                        <div className="col-lg-12 pb-4 mx-lg-2">
                            <h1 className="register-title py-4 fs-2 label-font">Please Confirm Your Registration Details</h1>

                            <div className=" mb-4">
                                <div className="col-12 col-lg-6 my-1 my-lg-2 form-group">
                                    <label className="label-font ps-1">Selected Programme to Participate</label>
                                    <div>
                                        <ul className="preview-data">
                                            {participantsData?.event_details?.map((elem) => (
                                                <li key={elem.id} className="event-label px-1">
                                                    {elem.name}
                                                </li>
                                            ))}

                                        </ul>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6 my-1 my-lg-2 form-group">
                                    <label className="label-font ps-1">Accomodation</label>
                                    <div className="preview-data text-capitalize px-3">{participantsData?.accomodation}</div>
                                </div>

                            </div>

                            <div className="row mb-4">
                                {participantsData?.participant_detail?.map((elem, index) => (
                                    <div key={index} className="card-div col-12 col-lg-6">
                                        <div className="participants-title d-flex justify-content-between align-items-center" id="participants-title-id">

                                            {index === 0 ?
                                                <h4 className="register-title pt-4 label-font col-9">Main Participant</h4>
                                                :
                                                <h4 className="register-title pt-4 label-font col-9">Participant {index}</h4>
                                            }
                                        </div>
                                        <div className="box">
                                            <div className="row">
                                                <div className="col-12 participant-f from-group mb-3">
                                                    <label className="label-font col pl-0">Email</label>
                                                    <div className="preview-data">{elem.email}</div>
                                                </div>

                                                <div className="col-12 participant-f from-group mb-3">
                                                    <label className="label-font col pl-0">Full Name</label>
                                                    <div className="preview-data text-capitalize">{elem.full_name}</div>
                                                </div>

                                                <div className="col-5 participant-f mb-3">
                                                    <label className="label-font col pl-0"><span>Sex</span></label>
                                                    <div className="preview-data text-capitalize">{elem.sex}</div>
                                                </div>

                                                <div className="col-7 participant-l pl-2 mb-3">
                                                    <label className="label-font col pl-0"><span>Religion</span></label>
                                                    <div className="preview-data text-capitalize">{elem.religion}</div>
                                                </div>

                                                <div className="col-12 col-md-5 participant-f mb-3">
                                                    <label className="label-font col pl-0"><span>Phone</span></label>
                                                    <div className="preview-data">{elem.phone}</div>
                                                </div>

                                                <div className="col-12 participant-f mb-3">
                                                    <label className="label-font col pl-0"><span>Address</span></label>
                                                    <div className="row mx-0">
                                                    <div className="col-12 col-md-6 px-0 pe-md-3">
                                                            <span className="ms-0 ps-0 addressLabel">Country</span>
                                                            <div className="preview-data text-capitalize">{elem.country_name}</div>
                                                        </div>

                                                        <div className="col-12 col-md-6 px-0 mt-3 my-md-0">
                                                            <span className="ms-0 ps-0 addressLabel">State</span>
                                                            <div className="preview-data text-capitalize">{elem.state_name ? elem.state_name : '-'}</div>
                                                        </div>

                                                        <div className="col-12 col-md-6 col-xl-4 px-0 pe-md-3 mt-3">
                                                            <span className="ms-0 ps-0 addressLabel">City</span>
                                                            <div className="preview-data text-capitalize">{elem.city_new_name ? elem.city_new_name : '-'}</div>
                                                        </div>

                                                        <div className="col-12 col-md-6 col-xl-4 px-0 mt-3">
                                                            <span className="ms-0 ps-0 addressLabel">Street</span>
                                                            <div className="preview-data text-capitalize">{elem.street ? elem.street : '-'}</div>
                                                        </div>

                                                        <div className="col-12 col-md-6 col-xl-4 px-0 pe-md-3 ps-xl-3 pe-xl-0 mt-3">
                                                            <span className="ms-0 ps-0 addressLabel">Zip Code</span>
                                                            <div className="preview-data">{elem.zip_code ? elem.zip_code : '-'}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-8 participant-f mb-3">
                                                    <label className="label-font col pl-0"><span>Visa Support Letter</span></label>
                                                    <div className="preview-data col-6 col-md-7 text-capitalize">{elem.visa}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* <div className="row d-flex justify-content-center justify-content-lg-end mt-3">
                                <Button as = {Link} to={`/edit-registration/${slug}`} className="send-btn btn-warning col-5 col-lg-2 my-2 py-2 mx-1" type="button">
                                    Edit
                                </Button>
                                <Button as = {Link} to={`/register-confirmation/${slug}`} className="contact-btn send-btn col-5 col-lg-2 my-2 py-2 mx-1" type="button">
                                    Confirm
                                </Button>
                            </div> */}
                            <div className="row d-flex justify-content-center justify-content-lg-end mt-3">
                                {/* <Button as={Link}
                                    to = '/edit-registration/'
                                    state= {{ id: slug }}
                                    className="send-btn btn-warning col-5 col-lg-2 my-2 py-2 mx-1" type="button">
                                    Edit
                                </Button> */}
                                <Button as = {Link} to={`/edit-registration/${slug}`} className="send-btn btn-warning col-5 col-lg-2 my-2 py-2 mx-1" type="button">
                                    Edit
                                </Button>

                                <Button      
                                    className="contact-btn send-btn col-5 col-lg-2 my-2 py-2 mx-1" type="button"  onClick={() => sendEmail(slug)}
                                    >
                                    Confirm
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer links={props.links} contacts={props.contacts} footerDescription={props.footerDescription} />
        </>

    )
}

export default RegisterPreviewPage;