import React, { useEffect, useState, useRef } from "react";
import { Footer, Header } from "../../Common";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { Api, Images } from "../../Constants";
import ReCAPTCHA from "react-google-recaptcha";
import './ContactUs.css';

const Feedback = (props) => {
  const { from, handleResend } = props;
  return (<div className="d-flex flex-column align-items-center py-3">
    <img src={Images.success} className="img-fluid pb-3" />
    <p className="fs-5 text-center">
      {`Hello`} <span className="text-capitalize fw-bold">{from.toLowerCase()}</span>{`, your message has been submitted successfully.`}
    </p>
    <button className="btn btn-success fs-5 re-send" onClick={handleResend}>Send Again</button>
  </div>);
}

const ContactUs = (props) => {
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
  const [inquirer, setInquirer] = useState("");
  const [loading, setLoading] = useState(true);
  const [captcha, setCaptcha] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);

  const onSubmit = async (data) => {
    if (captcha) {
      setCaptchaError(false);
      data['g-recaptcha-response'] = captcha;
      const res = await Api.postContactUs(data);
      console.log("res", res);
      if (res.status === true) {
        localStorage.setItem("inquiry_detail", JSON.stringify({ from: data.fullname }));
        setInquirer(data.fullname);
      }
    } else {
      setCaptchaError(true);
    }
  }

  // const handleIFrame = (e) => setLoading(false);
  const handleResend = () => {
    setInquirer("");
    localStorage.removeItem("inquiry_detail");

    reset();
  };

  const getInquiry = () => {
    setLoading(true);
    const detail = localStorage.getItem("inquiry_detail");
    if (!detail) return setLoading(false);
    const { from } = JSON.parse(detail);
    setInquirer(from);
    setLoading(false);
  }

  const onChangeCaptcha = (value) => {
    // Set the value for the ReCAPTCHA field in the form data
    setCaptcha(value);
    setValue('g-recaptcha-response', value, { shouldValidate: true });
  };

  useEffect(() => {
    getInquiry();
  }, []);

  return (
    <div>
      <Header links={props.links} />
      <div className="contactus py-4">
        <div className="container ">
          <div className="row mx-0">
            {inquirer ? (
              <div className=" col-md-6 pb-4">

                <Feedback from={inquirer} handleResend={handleResend} />
              </div>
            ) : (
              <div className=" col-md-6 pb-4">
                <h1 className="contactus-title py-4 text-uppercase fs-3 label-font">Contact Us</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row mb-4">
                    <div className="col form-group">
                      <label className="label-font ps-1">Full Name *</label>
                      <input
                        {...register(`fullname`, {
                          required: 'Full Name is required',
                          pattern: {
                            value: /^[A-Za-z]+(?:\s[A-Za-z]+)+$/,
                            message: 'Invalid full name'
                          }
                        })}
                        type="text"
                        className={`form-control ${errors?.fullname && 'border-danger'}`}
                        placeholder="Full Name"
                      />
                      {errors?.fullname &&
                        <div dangerouslySetInnerHTML={{ __html: errors.fullname.message }} className='text-danger' />
                      }


                      {/* {errors.fullname && (
                        <div className="invalid-feedback">
                          Full Name is required.
                        </div>
                      )} */}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-12 col-xxl-5 form-group mb-4 mb-xxl-0">
                      <label className="label-font ps-1">Phone Number *</label>
                      <input
                        {...register(`phone`, {
                          required: 'Valid phone number is required',
                          pattern: {
                            // value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                            value: /^(\+\d{1,4})?[0-9 -]{7,}$/,
                            message: 'Valid phone number is required'
                          }
                        })}
                        type="text"
                        className={`form-control ${errors?.phone && 'border-danger'}`}
                        placeholder="Phone Number"
                      />
                      {errors?.phone &&
                        <div dangerouslySetInnerHTML={{ __html: errors.phone.message }} className='text-danger' />

                      }
                      {/* {errors.phone && (
                        <div className="invalid-feedback">
                          Phone Number is required.
                        </div>
                      )}                      */}
                    </div>

                    <div className="col form-group">
                      <label className="label-font ps-1">Email *</label>
                      <input
                        {...register(`email`, {
                          required: 'Valid email is required',
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            message: 'Valid email is required'
                          }
                        })}
                        // onBlur={checkEmail}
                        type="text"
                        className={`form-control ${errors?.email && 'border-danger'}`}
                        placeholder="Email"
                      />

                      {errors?.email &&
                        <div dangerouslySetInnerHTML={{ __html: errors.email.message }} className='text-danger' />
                      }
                      {/* {errors.email && (
                        <div className="invalid-feedback">
                          Email is required.
                        </div>
                      )} */}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col form-group">
                      <label className="label-font ps-1">Address *</label>
                      <input
                        {...register(`address`, {
                          required: 'Address is required',
                        })}
                        type="text"
                        className={`form-control ${errors?.address && 'border-danger'}`}
                        placeholder="Address"
                      />
                      {errors?.address && <div className="text-danger">{errors.address.message}</div>}

                      {/* {errors.address && (
                        <div className="invalid-feedback">
                          Address is required.
                        </div>
                      )} */}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col form-group">
                      <label className="label-font ps-1">Subject *</label>
                      <input
                        {...register(`subject`, {
                          required: 'Subject is required',
                        })}
                        type="text"
                        className={`form-control ${errors?.subject && 'border-danger'}`}
                        placeholder="Subject"
                      />
                      {errors?.subject && <div className="text-danger">{errors.subject.message}</div>}

                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col form-group">
                      <label className="label-font ps-1">Message *</label>
                      {/* <textarea
                        className={`form-control input-font ${errors.message ? "is-invalid" : ""}`}
                        required
                        {...register("message")}
                        placeholder="Leave a Message Here"
                        rows="4"
                        onInvalid={(e) => e.target.setCustomValidity("Message is required.")}
                        onChange={(e) => e.target.setCustomValidity("")} // Reset the custom validity on change
                      >
                      </textarea> */}
                      <textarea
                        {...register(`message`, {
                          required: 'Message is required',
                        })}
                        type="text"
                        rows="4"
                        className={`form-control ${errors?.message && 'border-danger'}`}
                        placeholder="Leave a Message Here"
                      />
                      {errors?.message && <div className="text-danger">{errors.message.message}</div>}

                      {/* {errors.message && (
                        <div className="invalid-feedback">
                          Message is required.
                        </div>
                      )} */}
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col form-group">
                      <ReCAPTCHA
                        sitekey="6LfdWlMpAAAAADIALaeRh1LZwHDpRPa9uh5tHYxP"
                        onChange={onChangeCaptcha}
                        size="normal"
                      />
                      {captchaError && <span className="text-danger">Captcha is required</span>}
                    </div>
                  </div>

                  <div className=" d-flex justify-content-end">
                    <Button className="contact-btn send-btn px-4" type="submit">
                      Send
                    </Button>
                  </div>
                </form>
              </div>
            )}
            <div className=" col-md-6 py-4 px-0 px-lg-4">
              {/* <img src={Images.dharmalogo1} alt="dharma" className="img-fluid w-100" /> */}

              {/* <iframe
                title="mapFrame"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3531.9471396149584!2d85.37237297582801!3d27.718918324980454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1b48aba75f4f%3A0xf0ea8f4c2b765e31!2sDHARMA%20TELEVISION%20HD!5e0!3m2!1sen!2snp!4v1700735368342!5m2!1sen!2snp" width="100%" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
              ></iframe> */}
            </div>
          </div>
        </div>
      </div>
      <Footer links={props.links} contacts={props.contacts} footerDescription={props.footerDescription} />
    </div>
  );
};

export default ContactUs;
