// import { configureStore } from '@reduxjs/toolkit';
// import { userReducer } from '../Pages/Reducer/userSlice';
// import { routeReducer } from '../Pages/Reducer/routesSlice';

// // Load state from sessionStorage
// const persistedState = {
//     route: JSON.parse(sessionStorage.getItem('reduxState')) || {},
//     user: JSON.parse(sessionStorage.getItem('reduxUserState')) || {},
// };


// const store = configureStore({
//     reducer: {
//         route: routeReducer,
//         user: userReducer,
//     },
//     preloadedState: persistedState,
// });

// export default store;

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

import rootReducer from './reducers'; 
import {thunk} from 'redux-thunk';
import { createLogger } from 'redux-logger';

const middlewares = [thunk];

// Add the logger middleware only in development
if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger());
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  }).concat(middlewares),
// devTools: process.env.NODE_ENV !== 'production',
});

// store.subscribe(() => {
//   console.log('Dispatched action:', store.getState());
// });

const persistor = persistStore(store);

export { store, persistor };

