import React, { useEffect, useState } from "react";
import { Api } from "../../Constants";
import { useSelector } from "react-redux";

const ParticipantCountry = ({
  index,
  onCountryChange,
  onStateChange,
  onCityChange,
  register,
  errors,
  setValue,
  selectedCountryParent,
  selectedStateParent,
  selectedCityParent,
}) => {
  // react redux useSelector hook to access the state
  const user = useSelector((state) => state.user);

  const userDetails = user && user.isLoggedIn ? user.details : null;

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState();
  const [statesAvailable, setStatesAvailable] = useState(true);
  useEffect(() => {
    // Fetch countries on mount
    getCountryList();

    if (selectedCityParent) {
      setValue(`city_new_name[${index}]`, selectedCityParent);
    }
  }, []);

  const getCountryList = async () => {
    const res = await Api.getCountries();
    if (!res) return;
    const countryOptions = res?.countries.map((country) => ({
      value: country.id,
      label: country.name,
    }));
    setCountries(countryOptions);

    // Default index of India as of 2023.
    const defaultIndex = 100;
    const defaultCountryId = countryOptions[defaultIndex].value;

    // Set default country and trigger cascading updates
    setDefaultCountry(countryOptions[defaultIndex]);

    // Determine the selected country ID
    const selectedCountryId =
      selectedCountryParent !== undefined
        ? selectedCountryParent
        : defaultCountryId;

    // const selectedCountryId =
    //     !selectedCountryParent ? defaultCountryId : selectedCountryParent !== undefined ? selectedCountryParent : defaultCountryId

    setSelectedCountry(selectedCountryId);
    setValue(`country_id[${index}]`, selectedCountryId);

    onCountryChange(index, selectedCountryId);

    // Fetch states for the selected country
    getStates(selectedCountryId);
  };

  const handleCountryChange = (id) => {
    setSelectedCountry(id);
    onCountryChange(index, id);
    setValue(`state_id[${index}]`, null);
    getStates(id);
    // setCities([]);
  };

  const handleStateChange = (id) => {
    setSelectedState(id);
    onStateChange(index, id);
    // getCities(id);
  };

  const getStates = async (id) => {
    const res = await Api.getStateAccToCountry(id);
    if (res) {
      setStatesAvailable(true);
      const stateOptions = res.state_details.map((state) => ({
        value: state.id,
        label: state.name,
      }));

      setStates(stateOptions);

      // If selectedStateParent is provided, set it and trigger the change
      if (selectedStateParent) {
        handleStateChange(selectedStateParent);
        setValue(`state_id[${index}]`, selectedStateParent);
        getCities(selectedStateParent);
      } else {
        handleStateChange(4853);
        setValue(`state_id[${index}]`, 4853);
        getCities(4853);
      }
    } else {
      setStatesAvailable(false);
      setSelectedState("");
      setValue(`state_id[${index}]`, null);
      setStates([]);
    }
  };

  const getCities = async (id) => {
    const res = await Api.getCityAccToState(id);
    if (res) {
      const cityOptions = res.state_details.map((city) => ({
        // Assuming city data is in 'city_details'
        value: city.id,
        label: city.name,
      }));

      setCities(cityOptions);

      // If selectedCityParent is provided, set it
      if (selectedCityParent) {
        setSelectedCity(selectedCityParent);
        setValue(`city_id[${index}]`, selectedCityParent);
      }
    } else {
      setCities([]);
    }
  };

  return (
    <div className="row mx-0">
      <div className="col-12 col-md-6 col-xxl-4 px-0 pe-md-3">
        <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
          Country *
        </label>
        {userDetails?.country_name ? (
          <>
            <input
              type="text"
              className="form-control"
              // placeholder="Email"
              value={user.details?.country_name}
              disabled
            />
          </>
        ) : (
          <>
            <select
              {...register(`country_id[${index}]`, {
                required: "Country is required.",
              })}
              className={`form-control ${
                errors?.country_id?.[index] && "border-danger"
              }`}
              value={selectedCountry}
              onChange={(e) => handleCountryChange(e.target.value)}
            >
              {/* <option value="">Choose option</option> */}
              {countries.map((elem) => (
                <option key={elem.value} value={elem.value}>
                  {elem.label}
                </option>
              ))}
            </select>
            {errors?.country_id?.[index] && (
              <div
                dangerouslySetInnerHTML={{
                  __html: errors.country_id[index].message,
                }}
                className="text-danger"
              />
            )}
          </>
        )}
      </div>
      <div className="col-12 col-md-6 col-xxl-4 mt-3 my-md-0 px-0 px-xxl-2">
        <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
          State{" "}
        </label>
        {/* {state.length > 0 && ( */}
        {userDetails?.state_name ? (
          <>
            <input
              type="text"
              className="form-control"
              // placeholder="Email"
              value={user.details?.state_name}
              disabled
            />
          </>
        ) : (
          <>
            <select
              {...register(`state_id[${index}]`, {
                required: statesAvailable
                  ? {
                      value: true,
                      message: "Please select a state",
                    }
                  : false,
              })}
              className={`form-control ${
                errors?.state_id?.[index] && "border-danger"
              }`}
              value={selectedState}
              onChange={(e) => handleStateChange(e.target.value)}
            >
              <option value="">Choose option</option>
              {states.map((elem) => (
                <option key={elem.value} value={elem.value}>
                  {elem.label}
                </option>
              ))}
            </select>
            {errors?.state_id?.[index] && (
              <div
                dangerouslySetInnerHTML={{
                  __html: errors.state_id[index].message,
                }}
                className="text-danger"
              />
            )}
          </>
        )}

        {/* <select
                    {...register(`state_id[${index}]`, { required: 'State is required.' })}
                    className={`form-control ${errors?.state_id?.[index] && 'border-danger'}`}
                    value={selectedState}
                    onChange={(e) => handleStateChange(e.target.value)}
                >
                    <option value="">Choose option</option>
                    {states.map((elem) => (
                        <option key={elem.value} value={elem.value}>
                            {elem.label}
                        </option>
                    ))}
                </select>
                {errors?.state_id?.[index] &&
                    <div dangerouslySetInnerHTML={{ __html: errors.state_id[index].message }} className='text-danger' />

                } */}
        {/* )}     */}
      </div>
      <div className="col-12 col-md-6 col-xxl-4 mt-3 my-xxl-0 px-0 pe-md-3 ps-xxl-3 pe-xxl-0">
        <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
          City *
        </label>
        {/* {city.length > 0 && ( */}
        {userDetails?.city ? (
          <>
            <input
              type="text"
              className="form-control"
              // placeholder="Email"
              value={user.details?.city}
              disabled
            />
          </>
        ) : (
          <>
            <input
              {...register(`city_new_name[${index}]`, {
                required: "City is required",
              })}
              type="text"
              className={`form-control ${
                errors?.city_new_name?.[index] && "border-danger"
              }`}
              placeholder="City"
            />
            {/* <select
                    {...register(`city_id[${index}]`, { required: 'City is required.' })}
                    className={`form-control ${errors?.city_id?.[index] && 'border-danger'}`}
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                >
                    <option value="">Choose option</option>
                    {cities.map((elem) => (
                        <option key={elem.value} value={elem.value}>
                            {elem.label}
                        </option>
                    ))}
                </select> */}
            {errors?.city_new_name?.[index] && (
              <div className="text-danger">
                {errors.city_new_name[index].message}
              </div>
            )}
            {/* )} */}
          </>
        )}
      </div>
      <div className="col-12 col-md-6 col-xxl-4 mt-3 my-xxl-0 my-xxl-3 px-0 pe-xxl-3">
        <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
          Street *
        </label>
        {userDetails?.street ? (
          <>
            <input
              type="text"
              className="form-control"
              // placeholder="Email"
              value={user.details?.street}
              disabled
            />
          </>
        ) : (
          <>
            <input
              {...register(`street[${index}]`, {
                required: "Street is required",
              })}
              type="text"
              className={`form-control ${
                errors?.street?.[index] && "border-danger"
              }`}
              placeholder="Street"
            />
            {errors?.street?.[index] && (
              <div
                dangerouslySetInnerHTML={{
                  __html: errors.street[index].message,
                }}
                className="text-danger"
              />
            )}
          </>
        )}
      </div>
      {/* <div className="col-12 col-md-6 col-xxl-4 mt-3 px-0 pe-md-3 ps-xxl-2 pe-xxl-0">
        <label className="form-label ms-0 ps-0 addressLabel text-capitalize">
          Zip Code *
        </label>
        {userDetails?.zip_code ? (
          <input
            type="text"
            className="form-control"
            // placeholder="Email"
            value={user.details?.zip_code}
            disabled
          />
        ) : (
          <>
            <input
              {...register(`zip_code[${index}]`, {
                required: "Valid Zip Code is required",
                pattern: {
                  value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                  message: "Valid Zip Code is required",
                },
              })}
              type="text"
              className={`form-control ${
                errors?.zip_code?.[index] && "border-danger"
              }`}
              placeholder="Zip Code"
            />
            {errors?.zip_code?.[index] && (
              <div
                dangerouslySetInnerHTML={{
                  __html: errors.zip_code[index].message,
                }}
                className="text-danger"
              />
            )}
          </>
        )}
      </div> */}
    </div>
  );
};

export default ParticipantCountry;
