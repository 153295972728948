// import { userReducer } from '../Pages/Reducer/userSlice';
// import { routeReducer } from '../Pages/Reducer/routesSlice';

// export {
//     userReducer,
//     routeReducer,
// }

// reducers.js
// reducer.js
import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { userSlice } from '../Pages/Reducer/userSlice';
import { routesSlice } from '../Pages/Reducer/routesSlice';

const userPersistConfig = {
  key: 'user',
  storage,
};

const routePersistConfig = {
  key: 'route',
  storage,
};

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userSlice.reducer),
  route: persistReducer(routePersistConfig, routesSlice.reducer),
});

export default rootReducer;

