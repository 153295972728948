import React, { useEffect, useRef, useState } from "react";
import { Footer, Header } from "../../Common";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./BookConcert.css";
import { FormSelect } from "react-bootstrap";
import { Api, Images } from "../../Constants";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateRoute } from "../Reducer/routesSlice";
import ParticipantCountry from "../Register/ParticipantCountry";
import moment from "moment";
const accomodationOptions = [
  {
    id: "1",
    value: "free",
    label: "Free",
  },
  {
    id: "2",
    value: "hotel",
    label: "Hotel",
  },
];

const religionOptions = [
  {
    id: "1",
    value: "buddhism",
    label: "Buddhism",
  },
  {
    id: "2",
    value: "hinduism",
    label: "Hinduism",
  },
  {
    id: "3",
    value: "islam",
    label: "Islam",
  },
  {
    id: "4",
    value: "christainity",
    label: "Christainity",
  },
  {
    id: "5",
    value: "sikhism",
    label: "Sikhism",
  },
  {
    id: "6",
    value: "others",
    label: "Others",
  },
  {
    id: "7",
    value: "prefer not to say",
    label: "Prefer Not to Say",
  },
];

const genderOption = [
  {
    id: "1",
    value: "male",
    label: "Male",
  },
  {
    id: "2",
    value: "female",
    label: "Female",
  },
  {
    id: "3",
    value: "other",
    label: "Others",
  },
];

const visaSupportOptions = [
  {
    id: "1",
    value: "yes",
    label: "Yes",
  },
  {
    id: "2",
    value: "no",
    label: "No",
  },
];

const BookConcert = (props) => {
  const [eventId, setEventId] = useState(null);
  const [participants, setParticipants] = useState([{ id: 1 }]);

  const [participattionDays, setParticipationDays] = useState([]);
  const [categoryData, setCategory] = useState([]);
  const [accomodation, setAccomodation] = useState(accomodationOptions);

  const [maxHeight, setMaxHeight] = useState(0);
  const [religion, setReligion] = useState(religionOptions);
  const [gender, setGenderOption] = useState(genderOption);
  const [visaSupport, setVisaSupport] = useState(visaSupportOptions);

  const [paymentType, setPaymentType] = useState("card_payment"); // Set the default value

  const [selectedAccomodation, setSelectedAccomodation] = useState();
  const [selectedGender, setSelectedGender] = useState(gender[1].value);
  const [selectedReligion, setSelectedReligion] = useState();
  const [selectedVisaSupport, setSelectedVisaSupport] = useState();
  const [selectedCategory, setSelectedCategory] = useState();

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [exampleIndex, setSelectedIndex] = useState(0);

  const [agree, setAgree] = React.useState(false);
  const [checked, setChecked] = React.useState(false);

  const [applicationId, setApplicationId] = useState();
  const [applicantuserId, setApplicantUserId] = useState();
  const [slug, setSlug] = useState(null);
  const [_, forceUpdate] = useState();
  const {
    clearErrors,
    getValues,
    setValue,
    register,
    handleSubmit,
    unregister,
    setError,
    control,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const pathName = useParams();
  const location = useLocation();
  const textareaRef = useRef();
  const getProgramList = async () => {
    const res = await Api.getProgrammes();
    console.log("event:", res);
    if (!res) return;
    setParticipationDays(res?.event_details);
  };

  const getEventCategoryList = async (value) => {
    const res = await Api.getEventCategory(value);
    if (!res) return;
    setCategory(res?.registration_category);
  };

  // callback function to store the country list
  const handleCountryChange = (index, id) => {
    // Update a participant country with the given index
    setSelectedCountry((prev) => ({ ...prev, [index]: id }));
    setValue(`state_id[${index}]`, null);
  };

  // callback function to store the state list
  const handleStateChange = (index, id) => {
    // Update a participant's state with the given index
    setSelectedState((prev) => ({ ...prev, [index]: id }));
  };

  // callback function to store the city list
  const handleCityChange = (index, id) => {
    // Update a participant's city with the given index
    setSelectedCity((prev) => ({ ...prev, [index]: id }));
  };

  // function to get the participant details and display in the edit pariticipant form
  const getParticipantsDetail = async (id) => {
    const res = await Api.getParticipantDataFromUserId(id);
    if (res.status) {
      setParticipants(res.participant_detail.participant_detail);
      // set value using setValue of react-hook-form for the fields
      const selectedEventIds = res.participant_detail.event_details.map(
        (event) => event.id
      );
      const selectedEventName = res.participant_detail.event_details.map(
        (event) => event.name
      );
      const selectedEventVenue = res.participant_detail.event_details.map(
        (event) => event.venue
      );
      const selectedEventDate = res.participant_detail.event_details.map(
        (event) => event.date
      );
      console.log("selectedEventsIds", selectedEventIds);
      const selectedid = selectedEventIds[0];
      console.log("selectedid", selectedid);
      setEventId(selectedid);
      setValue(
        `event_id`,
        `${selectedEventVenue} - ${selectedEventName}  (${moment(
          selectedEventDate
        ).format("MMM DD, YYYY")})`
      );
      getEventCategoryList(selectedid);
      setValue("registration_fee_id", res.participant_detail.event_category_id);
      setValue(`number_of_tickets`, res.participant_detail.number_of_tickets);

      // res.participant_detail.participant_detail.forEach((elem, index) => {
      setValue(
        `full_name`,
        res.participant_detail.participant_detail.full_name
      );
      setValue(`user_uuid`, res.participant_detail.participant_detail.user_id);
      setValue(`phone`, res.participant_detail.participant_detail.phone);
      setValue(`email`, res.participant_detail.participant_detail.email);
      setValue(
        `country_id[${exampleIndex}]`,
        res.participant_detail.participant_detail.country_id
      );
      setValue(
        `state_id[${exampleIndex}]`,
        res.participant_detail.participant_detail.state_id
      );
      // setValue(`city_id`, res.participant_detail.participant_detail.city_id);
      setValue(
        `city_new_name[${exampleIndex}]`,
        res.participant_detail.participant_detail.city_new_name
      );
      setValue(
        `street[${exampleIndex}]`,
        res.participant_detail.participant_detail.street
      );
      // setValue(
      //   `zip_code[${exampleIndex}]`,
      //   res.participant_detail.participant_detail.zip_code
      // );

      setSelectedCountry(() => ({
        [exampleIndex]: res.participant_detail.participant_detail.country_id,
      }));
      setSelectedState(() => ({
        [exampleIndex]: res.participant_detail.participant_detail.state_id,
      }));

      // });
    } else {
      // setParticipants({})
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (pathName.id !== undefined) {
        setSlug(pathName.id);
        await getParticipantsDetail(pathName.id);
        const textarea = document.getElementById("event_id");
        handleKeyDown(textarea);
      }

      await getProgramList();
      if (location.state) {
        console.log("res", location.state);
        setValue(
          "event_id",
          `${location.state.venue} - ${location.state.name} (${moment(
            location.state.date
          ).format("MMM DD, YYYY")})`
          // location.state.id
        );
        setEventId(location.state.id);
        const textarea = document.getElementById("event_id");
        handleKeyDown(textarea);
        getEventCategoryList(location.state.id);
        // setValue(
        //   "registration_fee_id",
        //   res.participant_detail.event_category_id
        // );
      } else if (pathName.id !== undefined) {
        // navigate("/#book-concert-section");
      } else {
        navigate("/#book-concert-section");
      }
    };

    fetchData();
  }, [pathName.id, errors, location.state]);

  // useEffect(() => {
  //     window.scrollTo(0, 0);
  //     if (location.state) {
  //         setSlug(location.state.id)
  //         getParticipantsDetail(location.state.id);
  //     }
  //     setCardHeight();
  //     getProgramList();
  // }, [location.state, location.state === null && errors]);

  // const handlePaymentChange = (value) => {
  //     setPaymentType(value);
  // };

  // function to handle the submit button
  // checks if the user clicked on the agree to privacy policy and terms and condition checkbox
  const handleAgreeCheck = () => {
    setChecked(!checked);
    if (checked) {
      setAgree(false);
    } else {
      setAgree(true);
    }
  };

  // function to handle the submit button
  const onSubmit = (data) => handleFormSubmit(data);

  const handleFormSubmit = async (data) => {
    const formattedData = {
      city_new_name: data.city_new_name?.[0],
      country_id: data.country_id?.[0],
      state_id: data.state_id?.[0],
      street: data.street?.[0],
      // zip_code: data.zip_code?.[0],
    };

    data.city_new_name = formattedData?.city_new_name;
    data.country_id = formattedData?.country_id;
    data.state_id = formattedData?.state_id;
    data.street = formattedData?.street;
    // data.zip_code = formattedData?.zip_code;
    data.event_id = eventId;
    const postData = {
      ...data,
      payment_method: "khalti",
    };

    let res;
    // post api call to register the user
    if (!slug) {
      res = await Api.postRegister(postData);
    } else {
      // post api call to update registered user
      res = await Api.updateConcertRegister(postData, slug);
    }

    if (res.status) {
      // if the post api returns success sets the application id and then navigates to the preview page
      setApplicationId(res.application_id);
      setApplicantUserId(res.user_id);
      navigate(`/preview-booking/${res.user_id}`);
      // navigate(`/preview-page/`, { state: { id: res.application_id } });
    } else {
      // if the post api returns error then displays the error message
      if (res.message) {
        const error = res.message;
        console.log(error);
        // sets error message for the fields using the setError of react-hook-form
        Object.keys(error).forEach((field) => {
          const errorMessage = error[field];

          // Check if the error message exists and has a length
          if (errorMessage && errorMessage.length > 0) {
            setError(field, {
              type: "manual",
              message: errorMessage, // Assuming errorMessage is a string or an array
            });
          }
        });
      }
    }
  };
  const options = participattionDays.map((day) => ({
    value: day.id,
    label: `${day.venue} - ${day.name} (${moment(day.date_from).format(
      "MMM DD, YYYY"
    )})`,
  }));

  function handleKeyDown(e) {
    // Reset field height
    if (e) {
      e.style.height = "inherit";

      // Get the computed styles for the element
      const computed = window.getComputedStyle(e);

      // Calculate the height
      const height =
        parseInt(computed.getPropertyValue("border-top-width"), 10) +
        parseInt(computed.getPropertyValue("padding-top"), 10) +
        e.scrollHeight +
        parseInt(computed.getPropertyValue("padding-bottom"), 10) +
        parseInt(computed.getPropertyValue("border-bottom-width"), 10);

      e.style.height = `${height}px`;
    }
  }
  return (
    <div>
      <Header links={props.links} />
      <div className="booking-div pb-4">
        <div className="container ">
          <div className="row mx-0">
            <div className=" col-md-12 py-3 mx-lg-2">
              <h1 className="register-title pt-4 fs-3 label-font text-uppercase">
                Book Concert
              </h1>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-12 col-md-6 col-xl-5 mt-3 form-group">
                    <label className="form-label col pl-0">
                      Programme to Book a Ticket*
                    </label>
                    {/* <Controller
                      name="event_id"
                      value={selectedAccomodation}
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (base) => ({
                              // ...base,
                              display: "flex",
                              fontFamily: "Roboto-Regular",
                              fontSize: "1rem",
                              fontWeight: "400",
                              borderRadius: "0.375rem",
                              backgroundColor: "white",
                              borderWidth: "1rem",
                              // borderColor: errors.event_id
                              //   ? "#dc3545"
                              //   : "#dee2e6",
                              boxShadow: errors.event_id
                                ? "0 0 0 1px #dc3545"
                                : "0 0 0 1px #dee2e6",
                              height: "33px",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              fontFamily: "Roboto-Regular",
                              padding: "0.3rem",
                              fontSize: "1rem",
                              backgroundColor: state.isSelected
                                ? "#dddddd"
                                color: "black",
                                : "white",
                              "&:hover": {
                                backgroundColor: "#dddddd",
                              },
                            }),
                          }}
                          {...field}
                          value={selectedAccomodation}
                          options={options}
                          onChange={(option) => {
                            setSelectedAccomodation(option.value);
                            getEventCategoryList(option.value);
                          }}
                        />
                      )}
                    /> */}
                    <textarea
                      {...register(`event_id`, {})}
                      // style={{ height: "110px" }}
                      id="event_id"
                      // onKeyDown={handleKeyDown}
                      // ref={textareaRef}
                      type="text"
                      className={`form-control overflow-hidden ${
                        errors?.event_id && "border-danger"
                      }`}
                      readOnly
                    />
                    {/* {participattionDays.length > 0 && (
                      <select
                        {...register("event_id", { required: true })}
                        className={`form-control ${
                          errors.event_id && "border-danger"
                        }`}
                        value={selectedAccomodation}
                        onChange={(e) => {
                          setSelectedAccomodation(e.target.value);
                          getEventCategoryList(e.target.value);
                        }}
                      >
                        {/* <option value="" hidden>
                          Choose option
                        </option> */}
                    {/* {participattionDays.map((elem) => (
                          <>
                            <option
                              key={elem.id}
                              value={elem.id}
                              title={`${elem.venue}-${elem.name}(${moment(
                                elem.date_from
                              ).format("MMM DD, YYYY")})`}
                            >
                              {elem.venue} - {elem.name} (
                              {moment(elem.date_from).format("MMM DD, YYYY")})
                            </option>
                          </>
                        ))} */}
                    {/* </select> */}
                    {/* )} */}
                    {errors.event_id && (
                      <div className="text-danger">Programme is required.</div>
                    )}
                  </div>
                  <div className="col-12 col-md-6 col-xl-3 mt-3 form-group">
                    <label className="form-label col pl-0">Category*</label>
                    {participattionDays.length > 0 && (
                      <select
                        {...register("registration_fee_id", { required: true })}
                        className={`form-control ${
                          errors.registration_fee_id && "border-danger"
                        }`}
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                      >
                        <option value="" hidden>
                          Choose option
                        </option>
                        {categoryData.map((elem) => (
                          <option key={elem.id} value={elem.id}>
                            {elem.name} ({elem.currency} {elem.rate})
                          </option>
                        ))}
                      </select>
                    )}
                    {/* </div> */}
                    {errors.registration_fee_id && (
                      <div className="text-danger">Category is required.</div>
                    )}
                  </div>

                  <div className="row me-0 pe-0">
                    <div className="col-12 col-md-6 col-xl-4 mt-3 form-group pe-0 pe-md-1 pe-xxl-2">
                      {/* <label className="label-font ps-1">Accomodation *</label> */}
                      <label className="form-label col pl-0">
                        <span>No. of tickets *</span>
                      </label>
                      <input
                        {...register(`number_of_tickets`, {
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a valid number",
                          },
                        })}
                        type="text"
                        className={`form-control ${
                          errors?.number_of_tickets && "border-danger"
                        }`}
                        placeholder="1"
                      />
                    </div>
                    {errors.number_of_tickets && (
                      <div className="text-danger">
                        Number of tickets is required.
                      </div>
                    )}
                  </div>

                  <div className="col-12 col-xl-8 px-0">
                    <div className="row mx-0">
                      <div className="col-12 col-md-6 col-xxl-4 mt-3">
                        <label className="form-label col pl-0">
                          <span>Full Name *</span>
                        </label>
                        <input
                          {...register(`full_name`, {
                            required: "Full Name is required",
                            pattern: {
                              value: /^[A-Za-z]+(?:\s[A-Za-z]+)+$/,
                              message: "Invalid full name",
                            },
                          })}
                          type="text"
                          className={`form-control ${
                            errors?.full_name && "border-danger"
                          }`}
                          placeholder="Full Name"
                        />
                        {errors?.full_name && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: errors.full_name.message,
                            }}
                            className="text-danger"
                          />
                        )}
                      </div>

                      <div className="col-12 col-md-6 col-xxl-4 form-group mt-3">
                        <label className="form-label col pl-0">
                          <span>Email *</span>
                        </label>
                        <input
                          {...register(`email`, {
                            required: "Valid email is required",
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: "Valid email is required",
                            },
                          })}
                          // onBlur={checkEmail}
                          type="text"
                          className={`form-control ${
                            errors?.email && "border-danger"
                          }`}
                          placeholder="Email"
                        />
                        {errors?.email && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: errors.email.message,
                            }}
                            className="text-danger"
                          />
                        )}
                      </div>

                      <div className="col-12 col-md-6 col-xxl-4 mt-3">
                        <label className="required form-label col pl-0">
                          <span>Phone *</span>
                        </label>
                        <input
                          {...register(`phone`, {
                            required: "Valid phone number is required",
                            pattern: {
                              // value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                              value: /^(\+\d{1,4})?[0-9 -]{7,}$/,
                              message: "Valid phone number is required",
                            },
                          })}
                          type="text"
                          className={`form-control ${
                            errors?.phone && "border-danger"
                          }`}
                          placeholder="Phone Number"
                        />
                        {errors?.phone && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: errors.phone.message,
                            }}
                            className="text-danger"
                          />
                        )}
                      </div>

                      <div className="col-12 participant-f mb-4 mt-3">
                        <div className="form-group">
                          <label className="form-label col pl-0">
                            <span>Address</span>
                          </label>
                          {!pathName.id ? (
                            <>
                              <ParticipantCountry
                                key={exampleIndex}
                                index={exampleIndex}
                                onCountryChange={handleCountryChange}
                                onStateChange={handleStateChange}
                                onCityChange={handleCityChange}
                                register={register}
                                errors={errors}
                                setValue={setValue}
                                selectedCountryParent={
                                  selectedCountry[exampleIndex]
                                }
                                selectedStateParent={
                                  selectedState[exampleIndex]
                                }
                                selectedCityParent={selectedCity[exampleIndex]}
                              />
                            </>
                          ) : (
                            <>
                              {selectedCountry && (
                                <>
                                  <ParticipantCountry
                                    key={exampleIndex}
                                    index={exampleIndex}
                                    onCountryChange={handleCountryChange}
                                    onStateChange={handleStateChange}
                                    onCityChange={handleCityChange}
                                    register={register}
                                    errors={errors}
                                    selectedCityParent={
                                      selectedCity[exampleIndex]
                                    }
                                    setValue={setValue}
                                    selectedCountryParent={
                                      selectedCountry[exampleIndex]
                                    }
                                    selectedStateParent={
                                      selectedState
                                        ? selectedState[exampleIndex]
                                        : undefined
                                    }
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* {!slug ? (
                  <>
                    <div className="mb-4">
                      <input
                        type="checkbox"
                        className="me-2"
                        onClick={handleAgreeCheck}
                        {...register(`terms_condition`, { required: true })}
                      />
                      <span>
                        I agree to{" "}
                        <Link to="/terms-and-condition" target="_blank">
                          Terms and Condition
                        </Link>{" "}
                        and{" "}
                        <Link to="/privacy-policy" target="_blank">
                          Privacy Policy
                        </Link>
                        .
                      </span>
                    </div>

                    <div className="col-12 col-xl-8 d-flex justify-content-end mt-3">
                      <Button
                        className="contact-btn send-btn px-4"
                        type="submit"
                        disabled={!agree}
                      >
                        Register
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="col-12 col-xl-8 d-flex justify-content-end mt-3">
                    <Button className="contact-btn send-btn px-4" type="submit">
                      Register
                    </Button>
                  </div>
                )} */}
                <div className="mb-4">
                  <input
                    type="checkbox"
                    className="me-2"
                    onClick={handleAgreeCheck}
                    {...register(`terms_condition`, { required: true })}
                  />
                  <span>
                    I agree to{" "}
                    <Link to="/terms-and-condition" target="_blank">
                      Terms and Condition
                    </Link>{" "}
                    and{" "}
                    <Link to="/privacy-policy" target="_blank">
                      Privacy Policy
                    </Link>
                    .
                  </span>
                </div>

                <div className="col-12 col-xl-8 d-flex justify-content-end mt-3">
                  <Button
                    className="contact-btn send-btn px-4"
                    type="submit"
                    disabled={!agree}
                  >
                    Register
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer
        links={props.links}
        contacts={props.contacts}
        footerDescription={props.footerDescription}
      />
      <ToastContainer />
    </div>
  );
};

export default BookConcert;
