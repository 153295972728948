import React, { useState, useEffect } from "react";
import { Footer, Header } from "../../Common";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { Api, HelperFunction, Images } from "../../Constants";
import { useSelector, useDispatch } from 'react-redux'
import { updateRoute } from "../Reducer/routesSlice";
import ParticipantCountry from "../Register/ParticipantCountry";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const genderOption = [
    {
        id: '1',
        value: 'male',
        label: 'Male'
    },
    {
        id: '2',
        value: 'female',
        label: 'Female',
    },
    {
        id: '3',
        value: 'other',
        label: 'Others'
    }
]

const religionOptions = [
    {
        id: '1',
        value: 'buddhism',
        label: 'Buddhism'
    },
    {
        id: '2',
        value: 'hinduism',
        label: 'Hinduism',
    },
    {
        id: '3',
        value: 'islam',
        label: 'Islam'
    },
    {
        id: '4',
        value: 'christainity',
        label: 'Christainity',
    },
    {
        id: '5',
        value: 'sikhism',
        label: 'Sikhism'
    },
    {
        id: '6',
        value: 'others',
        label: 'Others',
    },
    {
        id: '7',
        value: 'prefer not to say',
        label: 'Prefer Not to Say',
    }
]

const transportationOption = [
    {
        id: '1',
        value: 'airplane',
        label: 'Airplane'
    },
    {
        id: '2',
        value: 'bus',
        label: 'Bus',
    },
    {
        id: '3',
        value: 'train',
        label: 'Train'
    }
]

const TravelAssistance = (props) => {

    // react redux useSelector hook to access the state
    const user = useSelector(state => state.user)

    const userDetails = user && user.isLoggedIn ? user.details : null;

    const route = useSelector(state => state.route)

    // react redux useDispatch
    const dispatch = useDispatch();

    const [transportation, setTransportationOption] = useState(transportationOption);
    const [participants, setParticipants] = useState(1);
    const [gender, setGenderOption] = useState(genderOption);
    const [selectedGender, setSelectedGender] = useState(gender[1].value);
    const [religion, setReligion] = useState(religionOptions);
    const [selectedReligion, setSelectedReligion] = useState();
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [agree, setAgree] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [exampleIndex, setSelectedIndex] = useState(0);

    const [applicationId, setApplicationId] = useState();
    const [registerApplicationId, setRegistrationApplicationId] = useState();

    const [slug, setSlug] = useState(null);

    const { clearErrors, getValues, setValue, register, handleSubmit, unregister, setError, formState: { errors } } = useForm();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
    console.log('location.state', location)
    const id = new URLSearchParams(location.search).get('id');
    setRegistrationApplicationId(id);
    console.log('id', id);
        window.scrollTo(0, 0);
        if (location.state?.id) {
            setSlug(location.state.id);
            getParticipantsDetail(location.state.id);
        }

    }, [location.state && location.state.id]);

    const handleAgreeCheck = () => {
        setChecked(!checked)
        if (checked) {
            setAgree(false)
        }
        else {
            setAgree(true)
        }
    }

    const getParticipantsDetail = async (id) => {
        const res = await Api.getDataFromDonationId(id);
        if (res.status) {
            setParticipants(res.donor_detail);
            setSelectedCountry(() => ({ [exampleIndex]: res.donor_detail.country_id }));
            setSelectedState(() => ({ [exampleIndex]: res.donor_detail.state_id }));
            setValue(`email`, res.donor_detail.email);
            setValue(`full_name`, res.donor_detail.full_name);
            setValue(`phone`, res.donor_detail.phone);
            setValue(`sex`, res.donor_detail.sex);
            setValue(`religion`, res.donor_detail.religion);
            setValue(`country_id[${exampleIndex}]`, res.donor_detail.country_id)
            setValue(`state_id[${exampleIndex}]`, res.donor_detail.state_id)
            setValue(`city_new_name[${exampleIndex}]`, res.donor_detail.city_new_name);
            setValue(`street[${exampleIndex}]`, res.donor_detail.street);
            setValue(`zip_code[${exampleIndex}]`, res.donor_detail.zip_code);
        } else {

        }
    }

    // callback function to store the country list
    const handleCountryChange = (index, id) => {
        // Update a participant country with the given index
        setSelectedCountry((prev) => ({ ...prev, [index]: id }));
    };

    // callback function to store the state list
    const handleStateChange = (index, id) => {
        // Update a participant's state with the given index
        setSelectedState((prev) => ({ ...prev, [index]: id }));
    };

    // callback function to store the city list
    const handleCityChange = (index, id) => {
        // Update a participant's city with the given index
        setSelectedCity((prev) => ({ ...prev, [index]: id }));
    };

    const checkEmail = async (e) => {

        const inputValue = e.target.value;
        const res = await Api.checkEmail(inputValue);

        if (res.status !== false) {
            console.log('res.data', res.data);
            toast.success("Participant with this email already exists! Please login.", {
                position: toast.POSITION.TOP_RIGHT,
            });

            const route = {
                pathname: window.location.pathname,
                search: `?id=${registerApplicationId}`,
              };

            // Dispatch the updateRoute action before navigating
            dispatch(updateRoute(route))

            // navigate to the login route with state
            setTimeout(() => {
                navigate(`/login`, { state: { email: inputValue } });
            }, 2000)

        } else {
            console.log('status', res);
        }

    };

    const onSubmit = data => handleFormSubmit(data);

    const handleFormSubmit = async (data) => {
        console.log('data', data)
        const postData = {
            ...data,
        };

        let res;

        if (!slug) {
            res = await Api.postTravelAssistance(postData, registerApplicationId);
        } else {
            res = await Api.updateTravelAssistance(postData, slug);
        }
        if (res.status) {
            setApplicationId(res.user_id);
            navigate(`/preview-travel-details`, { state: { id: res.user_id } });
        } else {
            if (res.message) {
                const error = res.message;
                // sets error message for the fields using the setError of react-hook-form
                Object.keys(error).forEach(field => {
                    const errorMessage = error[field];

                    // Check if the error message exists and has a length
                    if (errorMessage && errorMessage.length > 0) {
                        setError(field, {
                            type: 'manual',
                            message: errorMessage // Assuming errorMessage is a string or an array
                        });
                    }
                });
            }

            // call setCardHeight to set the height of the addBtn-div if the error occurs
        }
    }
    return (
        <>
            <Header links={props.links} />

            <div className="container">
                <div className="pb-4">
                    <div className="row form-group mx-0">
                        <h1 className="register-title py-4 fs-2 label-font">Do you need assistance in Travel?</h1>

                        <div className="col-lg-8">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row mb-4">
                                    <div className="col-12 col-md-6 my-1 my-lg-2 form-group">
                                        <label className="form-label col pl-0"><span>Email *</span></label>
                                        {userDetails?.email ? (
                                            <input
                                                type="text"
                                                className='form-control'
                                                // placeholder="Email"
                                                value={user.details.email}
                                                disabled
                                            />
                                        ) : (
                                            <>
                                                <input
                                                    {...register(`email`, {
                                                        required: 'Valid Email is required.',
                                                        pattern: {
                                                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                            message: 'Valid email is required'
                                                        }
                                                    })}
                                                    onBlur={checkEmail}
                                                    type="text"
                                                    className={`form-control ${errors?.email && 'border-danger'}`}
                                                    placeholder="Email"
                                                />
                                                {errors?.email &&
                                                    <div dangerouslySetInnerHTML={{ __html: errors.email.message }} className='text-danger' />
                                                }
                                            </>
                                        )}

                                    </div>

                                    <div className="col-12 col-md-6 my-1 my-lg-2 form-group">
                                        <label className="form-label col pl-0"><span>Full Name *</span></label>
                                        {userDetails?.full_name ? (
                                            <>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    // placeholder="Email"
                                                    value={user.details.full_name}
                                                    disabled
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <input
                                                    {...register(`full_name`, {
                                                        required: 'Full Name is required.',
                                                        pattern: {
                                                            value: /^[A-Za-z]+(?:\s[A-Za-z]+)+$/,
                                                            message: 'Invalid full name'
                                                        }
                                                    })}
                                                    type="text"
                                                    className={`form-control ${errors?.full_name && 'border-danger'}`}
                                                    placeholder="Full Name"
                                                />
                                                {errors?.full_name &&
                                                    <div dangerouslySetInnerHTML={{ __html: errors.full_name.message }} className='text-danger' />
                                                }
                                            </>
                                        )}
                                    </div>

                                    <div className="col-5 col-md-4 participant-f my-1 my-lg-2 form-group">
                                        <label className="required form-label col pl-0"><span>Sex *</span></label>
                                        {userDetails?.sex ? (
                                            <>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    // placeholder="Email"
                                                    value={user.details.sex}
                                                    disabled
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <select
                                                    {...register(`sex`, { required: 'Sex is required.' })}
                                                    className={`form-control ${errors?.sex && 'border-danger'}`}
                                                    defaultValue={selectedGender}
                                                    onChange={(e) => setSelectedGender(e.target.value)}
                                                >
                                                    {/* <option value="">Choose option</option> */}
                                                    {gender.map(elem =>
                                                        <option key={elem.value} value={elem.value}>{elem.label}</option>
                                                    )}
                                                </select>
                                                {errors?.sex &&
                                                    <div dangerouslySetInnerHTML={{ __html: errors.sex.message }} className='text-danger' />
                                                }
                                            </>
                                        )}
                                    </div>

                                    <div className="col-7 col-md-8 participant-l pl-2 my-1 my-lg-2">
                                        <label className="form-label col pl-0"><span>Religion*</span></label>
                                        {userDetails?.religion ? (
                                            <>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    // placeholder="Email"
                                                    value={user.details.religion}
                                                    disabled
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <select
                                                    {...register(`religion`, { required: 'Religion is required.' })}
                                                    className={`form-control ${errors?.religion && 'border-danger'}`}
                                                    // value={selectedReligion}
                                                    onChange={(e) => setSelectedReligion(e.target.value)}
                                                >
                                                    <option value="" hidden>Choose option</option>
                                                    {religion.map(elem =>
                                                        <option key={elem.value} value={elem.value}>{elem.label}</option>
                                                    )}
                                                </select>
                                                {errors?.religion &&
                                                    <div dangerouslySetInnerHTML={{ __html: errors.religion.message }} className='text-danger' />
                                                }
                                            </>
                                        )}

                                    </div>

                                    <div className="col-12 col-md-6 my-1 my-lg-2 form-group">
                                        <label className="required form-label col pl-0"><span>Phone *</span></label>
                                        {userDetails?.phone ? (
                                            <>
                                                <input
                                                    type="text"
                                                    className='form-control'
                                                    // placeholder="Email"
                                                    value={user.details.phone}
                                                    disabled
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <input
                                                    {...register(`phone`, {
                                                        required: 'Valid phone number is required.',
                                                        pattern: {
                                                            // value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                                                            value: /^(\+\d{1,4})?[0-9 -]{7,}$/,
                                                            message: 'Valid phone number is required'
                                                        }
                                                    })}
                                                    type="text"
                                                    className={`form-control ${errors?.phone && 'border-danger'}`}
                                                    placeholder="Phone Number"
                                                />
                                                {errors?.phone &&
                                                    <div dangerouslySetInnerHTML={{ __html: errors.phone.message }} className='text-danger' />
                                                }
                                            </>
                                        )}
                                    </div>

                                    <div className="col-12 participant-f my-1 my-lg-2">
                                        <label className="form-label col pl-0"><span>Address</span></label>

                                        <div className="form-group">

                                            {!location.state?.id ? (
                                                <>
                                                    <ParticipantCountry
                                                        key={exampleIndex}
                                                        index={exampleIndex}
                                                        onCountryChange={handleCountryChange}
                                                        onStateChange={handleStateChange}
                                                        onCityChange={handleCityChange}
                                                        register={register}
                                                        errors={errors}
                                                        setValue={setValue}
                                                        selectedCountryParent={selectedCountry[exampleIndex]}
                                                        selectedStateParent={selectedState[exampleIndex]}
                                                        selectedCityParent={selectedCity[exampleIndex]}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    {selectedCountry && (
                                                        <>
                                                            <ParticipantCountry
                                                                key={exampleIndex}
                                                                index={exampleIndex}
                                                                onCountryChange={handleCountryChange}
                                                                onStateChange={handleStateChange}
                                                                onCityChange={handleCityChange}
                                                                register={register}
                                                                errors={errors}
                                                                setValue={setValue}
                                                                selectedCountryParent={selectedCountry[exampleIndex]}
                                                                selectedStateParent={selectedState ? selectedState[exampleIndex] : undefined}
                                                                selectedCityParent={selectedCity[exampleIndex]}
                                                            />
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 my-1 my-lg-2 form-group">
                                        <label className="form-label col pl-0"><span>Arrival Date</span></label>
                                        <input
                                            {...register('arrival_date', {
                                                required: 'Arrival Date is required',
                                            })}
                                            type="date"
                                            className={`form-control my-2 ${errors?.arrival_date && 'border-danger'}`}
                                            placeholder="Arrival Date"
                                        />
                                        {errors?.arrival_date &&
                                            <div dangerouslySetInnerHTML={{ __html: errors.arrival_date.message }} className='text-danger' />
                                        }
                                    </div>
                                    <div className="col-12 col-md-6 my-1 my-lg-2 form-group">
                                        <label className="form-label col pl-0"><span>Departure Date *</span></label>
                                        <input
                                            {...register('departure_date', {
                                                required: 'Departure Date is required',
                                            })}
                                            type="date"
                                            className={`form-control my-2 ${errors?.departure_date && 'border-danger'}`}
                                            placeholder="Departure Date"
                                        />
                                        {errors?.departure_date &&
                                            <div dangerouslySetInnerHTML={{ __html: errors.departure_date.message }} className='text-danger' />
                                        }
                                    </div>

                                    <div className="col-12 my-1 my-lg-2 form-group">
                                        <label className="form-label col pl-0"><span>Select Mode of Transportation *</span></label>
                                        <div className="my-2 mx-2">
                                            {transportation?.map((elem, index) => (
                                                <div key={index} className="me-2 my-1">
                                                    <input
                                                        type="radio"
                                                        className="mx-2"
                                                        // onChange = {onChangeProgram}
                                                        value={elem.value}
                                                        {...register(`mode_of_transportation`, { required: 'Mode of Transportation is required' })}
                                                    />
                                                    <span>{elem.label}</span>
                                                </div>
                                            ))}
                                        </div>
                                        {errors?.mode_of_transportation &&
                                            <div dangerouslySetInnerHTML={{ __html: errors.mode_of_transportation.message }} className='text-danger' />
                                        }
                                    </div>
                                </div>

                                {!slug ? (
                                    <>
                                        <div className="my-4">
                                            <input type="checkbox" className="mx-2"
                                                onClick={handleAgreeCheck}
                                                {...register(`terms_condition`, { required: true })}
                                            />
                                            <span>
                                                I agree to <Link to='/terms-and-condition' target="_blank">
                                                    Terms and Condition
                                                </Link> and <Link to='/privacy-policy' target="_blank">
                                                    Privacy Policy
                                                </Link>.


                                            </span>
                                        </div>

                                        <div className=" d-flex justify-content-end mt-3">
                                            <Button className="contact-btn send-btn px-4" type="submit" disabled={!agree}>
                                                Submit
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <div className=" d-flex justify-content-end mt-3">
                                        <Button className="contact-btn send-btn px-4" type="submit">
                                            Submit
                                        </Button>
                                    </div>
                                )}
                            </form>
                        </div>
                        <div className=" col-lg-4">

                            <img src={Images.dharmalogo1} alt="dharma" className="img-fluid w-100" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer links={props.links} contacts={props.contacts} footerDescription={props.footerDescription} />

        </>
    )
}

export default TravelAssistance;