import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  details: {},
  currentUser: null,
  isLoggedIn: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.details = action.payload;
    },
    logout: (state) => {
      localStorage.clear();
      return initialState;
    },
    setCurrent: (state, action) => {
      state.details = action.payload;
    },
  },
});

export const { login, logout, setCurrent } = userSlice.actions;

export const userReducer = userSlice.reducer;
