import React, { useEffect, useState, useRef } from "react";
import { Footer, Header } from "../../Common";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import { Api, Images } from "../../Constants";
import ReCAPTCHA from "react-google-recaptcha";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import './Sponsor.css';
import ParticipantCountry from "../Register/ParticipantCountry";

const religionOptions = [
    {
        id: '1',
        value: '1',
        label: 'Buddhism'
    },
    {
        id: '2',
        value: '2',
        label: 'Hinduism',
    },
    {
        id: '3',
        value: '3',
        label: 'Islam'
    },
    {
        id: '4',
        value: '4',
        label: 'Christainity',
    },
    {
        id: '5',
        value: '5',
        label: 'Sikhism'
    },
    {
        id: '6',
        value: '6',
        label: 'Others',
    },
    {
        id: '7',
        value: '7',
        label: 'Prefer Not to Say',
    }
]

const genderOption = [
    {
        id: '1',
        value: '1',
        label: 'Male'
    },
    {
        id: '2',
        value: '2',
        label: 'Female',
    },
    {
        id: '3',
        value: '3',
        label: 'Others'
    }
]

const sponsorshipOption = [
    {
        id: '1',
        value: '1',
        label: 'Gold'
    },
    {
        id: '2',
        value: '2',
        label: 'Silver',
    },
    {
        id: '3',
        value: '3',
        label: 'Bronze'
    }
]


const Sponsor = (props) => {
    const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm();
    const [religion, setReligion] = useState(religionOptions);
    const [gender, setGenderOption] = useState(genderOption);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [sponsorship, setSponsorship] = useState(sponsorshipOption);
    const [selectedSponsorshipType, setselectedSponsorshipType] = useState();

    const animatedComponents = makeAnimated();

    // callback function to store the country list
    const handleCountryChange = (index, id) => {
        // Update a participant country with the given index
        setSelectedCountry((prev) => ({ ...prev, [index]: id }));

    };

    // callback function to store the state list
    const handleStateChange = (index, id) => {
        // Update a participant's state with the given index
        setSelectedState((prev) => ({ ...prev, [index]: id }));
    };

    // callback function to store the city list
    const handleCityChange = (index, id) => {
        // Update a participant's city with the given index
        setSelectedCity((prev) => ({ ...prev, [index]: id }));
    };

    return (
        <div>
            <Header links={props.links} />
            <div className="contactus">
                <div className="container ">
                    <div className="row mx-0">
                        <div className=" col-md-7 pb-4 ">
                            <h1 className="register-title py-4 fs-2 label-font">Be an event sponsor</h1>

                            <form>
                                <div className="row mb-4">
                                    <div className="col form-group">
                                        <label className="label-font ps-1">Full Name *</label>
                                        <input
                                            className={`form-control input-font ${errors.fullname ? "is-invalid" : ""}`}
                                            required
                                            {...register("fullname")}
                                            type="text"
                                            placeholder="Full Name"
                                            onInvalid={(e) => e.target.setCustomValidity("Full Name is required.")}
                                            onChange={(e) => e.target.setCustomValidity("")} // Reset the custom validity on change
                                        />
                                    </div>
                                </div>

                                {/* <div className="row mb-4">
                                    <div className="col form-group">
                                        <label className="required label-font ps-1"><span>Sex *</span></label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                            defaultValue={[gender[1]]}
                                            options={gender}
                                        />
                                    </div>

                                    <div className="col form-group">
                                        <label className="label-font ps-1">Religion*</label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                            options={religion}
                                        />
                                    </div>
                                </div> */}

                                <div className="row mb-4">
                                    <div className="col-12 col-md-6 participant-f">
                                        <div className="form-group">
                                            <label className="required form-label col pl-0"><span>Phone *</span></label>
                                            <input
                                                {...register(`phone`, {
                                                    required: 'Valid phone number is required',
                                                    pattern: {
                                                        // value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                                                        value: /^(\+\d{1,4})?[0-9 -]{7,}$/,
                                                        message: 'Valid phone number is required'
                                                    }
                                                })}
                                                type="text"
                                                className={`form-control ${errors?.phone && 'border-danger'}`}
                                                placeholder="Phone Number"
                                            />
                                            {errors?.phone &&
                                                <div dangerouslySetInnerHTML={{ __html: errors.phone.message }} className='text-danger' />

                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 participant-l pl-2">
                                        <div className="form-group ">
                                            <label className="form-label col pl-0"><span>Email *</span></label>
                                            <input
                                                {...register(`email`, {
                                                    required: 'Valid email is required',
                                                    pattern: {
                                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                        message: 'Valid email is required'
                                                    }
                                                })}
                                                type="text"
                                                className={`form-control ${errors?.email && 'border-danger'}`}
                                                placeholder="Email"
                                            />
                                            {errors?.email &&
                                                <div dangerouslySetInnerHTML={{ __html: errors.email.message }} className='text-danger' />
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 participant-f mb-3">
                                    <div className="form-group">
                                        <label className="form-label col pl-0"><span>Address</span></label>
                                        <ParticipantCountry
                                            index={0}
                                            onCountryChange={handleCountryChange}
                                            onStateChange={handleStateChange}
                                            onCityChange={handleCityChange}
                                            register={register}
                                            errors={errors}
                                            setValue={setValue}
                                            selectedCountryParent={selectedCountry[0]}
                                            selectedStateParent={selectedState[0]}
                                            selectedCityParent={selectedCity[0]}
                                        />
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-12 col-md-5 participant-f">
                                        <div className="form-group">
                                            <label className="required form-label col pl-0"><span>Company Name *</span></label>
                                            <input
                                                {...register(`company_name`, {
                                                    required: 'Company Name is required',
                                                })}
                                                type="text"
                                                className={`form-control ${errors?.company_name && 'border-danger'}`}
                                                placeholder="Company Name"
                                            />
                                            {errors?.company_name &&
                                                <div dangerouslySetInnerHTML={{ __html: errors.company_name.message }} className='text-danger' />

                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-7 participant-l pl-2">
                                        <div className="form-group ">
                                            <label className="form-label col pl-0"><span>Designation *</span></label>
                                            <input
                                                {...register(`designation`, {
                                                    required: 'Designation is required',
                                                })}
                                                type="text"
                                                className={`form-control ${errors?.designation && 'border-danger'}`}
                                                placeholder="Designation"
                                            />
                                            {errors?.email &&
                                                <div dangerouslySetInnerHTML={{ __html: errors.designation.message }} className='text-danger' />
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div className="col-7 participant-l pl-2 mb-4">
                                    <label className="form-label col pl-0"><span>Sponsorship type *</span></label>
                                    <select
                                        {...register(`sponsorship_type`, { required: 'Sponsorship type is required.' })}
                                        className={`form-control ${errors?.sponsorship_type && 'border-danger'}`}
                                        // value={selectedReligion}
                                        onChange={(e) => setselectedSponsorshipType(e.target.value)}
                                    >
                                        <option value="" hidden>Choose option</option>
                                        {sponsorshipOption.map(elem =>
                                            <option key={elem.value} value={elem.value}>{elem.label}</option>
                                        )}
                                    </select>
                                    {errors?.sponsorship_type &&
                                        <div dangerouslySetInnerHTML={{ __html: errors.sponsorship_type.message }} className='text-danger' />
                                    }
                                </div>

                                <div className=" d-flex justify-content-end">
                                    <Button className="contact-btn send-btn px-4" type="submit">
                                        Send
                                    </Button>
                                </div>
                            </form>
                        </div>
                        <div className=" col-md-5 py-4 px-0 ps-lg-4">
                            <img src={Images.dharmalogo1} alt="dharma" className="img-fluid w-100" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer links={props.links} contacts={props.contacts} footerDescription={props.footerDescription} />
        </div>
    );
};

export default Sponsor;
