import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { Api, HelperFunction, Images } from "../../Constants";
import { ImFacebook } from "react-icons/im";
import { FaLinkedinIn } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import { IoLogoTwitter } from "react-icons/io";
import { FaYoutube, FaLinkedin } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import { FaUser } from "react-icons/fa6";

import "./Header.css";
import { Button, NavItem } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import { useSelector, useDispatch } from "react-redux";
import { clearRedirect } from "../../Pages/Reducer/routesSlice";
import { logout } from "../../Pages/Reducer/userSlice";

const Header = (props) => {
  // const user = useSelector((state) => state.user);
  // const dispatch = useDispatch();

  // const handleLogout = () => {
  //     localStorage.removeItem('token')

  //     dispatch(clearRedirect());
  //     dispatch(logout());
  // }

  const handleDropdownClick = () => {
    const navbarCollapse = document.getElementById("basic-navbar-nav");
    const navbartoggler = document.getElementsByClassName("navbar-toggler")[0];

    if (navbarCollapse) {
      navbarCollapse.classList.remove("show");
    }

    if (navbartoggler) {
      navbartoggler.classList.add("collapsed");
    }
  };

  return (
    <>
      <Navbar expand="xl" className="header-nav my-lg-0 py-lg-0">
        <div className="container">
          {/* <Navbar.Brand href="/" title="Hope Fertility And Diagnostic Private Limited" className="mx-auto">
                        <img src={Images.logo} className="h-logo img-fluid ms-2 ms-lg-0" alt="Hope Fertility And Diagnostic Private Limited" />
                    </Navbar.Brand> */}
          <Navbar.Brand href="/" title="BTMC Foundation">
            <img
              src={`${HelperFunction.FILE_URL}/site_logo/${props.links.site_logo}`}
              className="h-logo img-fluid ms-2 ms-lg-0"
              alt="BTMC Foundation"
            />
            {/* <img src={Images.btmclogoindia} className="h-logo img-fluid ms-2 ms-lg-0" alt="BTMC Foundation India" /> */}
            {/* <span className="h-name">Dharma Ideal Campaign</span> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="">
            <GiHamburgerMenu className="hamburger" />{" "}
            <ImCross className="cross" style={{ height: "0.7rem" }} />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end flex-grow-1 d-flex align-items-start align-items-xl-center">
              <div className="d-flex flex-column my-2">
                <div className="row mx-0 mx-xl-0 d-none d-xl-block">
                  <div className="col col-md-12 d-flex justify-content-sm-end justify-content-end flex-row  align-items-xl-center my-1 py-1">
                    {/* <Link to='https://forms.gle/vrQjzptkWFncuuzo8' target="_blank" className="text-uppercase mx-2 h1-links d-none d-lg-block" title="Register Now" >Register Now</Link> */}
                    {/* <Link to='/donate-now' className="text-uppercase mx-2 h1-links d-none d-lg-block" title="Register Now" >Donate Now</Link> */}
                    <Link
                      to="https://dharmaideal.org/"
                      target="_blank"
                      className="text-capitalize external-links mx-3 mx-xl-2 my-2 my-lg-0"
                      title="International Buddhist Events 2024"
                    >
                      International Buddhist Events 2024, Nepal
                    </Link>
                    <Link
                      to="/contact-us"
                      className="text-capitalize nav-custom-edits mx-3 ms-xl-2 me-xl-0 my-2 my-lg-0"
                      title="Contact Us"
                    >
                      Contact Us
                    </Link>
                    {/* <Link to='/book-concert' className="ms-3">
                                            <button className='donate-btn btn py-1 px-3 px-xxl-4 text-capitalize'>
                                                Charity Live Music Tickets
                                            </button>
                                        </Link> */}
                    <HashLink to="#book-concert-section" className="ms-3">
                      <button className="donate-btn btn py-1 px-3 px-xxl-4 text-capitalize">
                        Charity Live Music Tickets
                      </button>
                    </HashLink>
                    {/* {user.isLoggedIn ? (
                                            <>
                                                <NavDropdown title={<span><FaUser className="me-1" /> {user.details.full_name}</span>} id="profile-dropdown " className="text-capitalize mx-2 h1-links d-none d-lg-block">
                                                    <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                                                </NavDropdown>
                                            </>
                                        ) : (
                                            <>
                                                <Link to='/login' className="text-uppercase mx-2 profile-icon" title="" ><FaUser /></Link>

                                            </>
                                        )} */}
                  </div>
                </div>
                <div className=" me-0 pe-0 mt-1 d-flex flex-column flex-xl-row justify-content-lg-end">
                  <NavDropdown
                    title="About"
                    className="text-uppercase mx-4 mx-xl-2  mt-4 my-xl-0 nav-custom-edits about-dropdown d-xl-flex align-items-center"
                  >
                    <NavDropdown.Item
                      as={Link}
                      to="/btmc-foundation"
                      onClick={handleDropdownClick}
                    >
                      BTMC Foundation
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/tri-yana-project"
                      onClick={handleDropdownClick}
                    >
                      Tri-yana Project
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/about-venerable-khem-sonam-rinpoche"
                      onClick={handleDropdownClick}
                    >
                      Ven. Khem Sonam Rinpoche
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/welcome-message"
                      style={{ wordWrap: "break-word" }}
                      onClick={handleDropdownClick}
                    >
                      Welcome Message
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/support-us"
                      onClick={handleDropdownClick}
                    >
                      Support Us
                    </NavDropdown.Item>
                  </NavDropdown>
                  {/* <NavDropdown title="Teaching" className="text-uppercase mx-4 mx-xl-2 my-xl-0 nav-custom-edits about-dropdown d-xl-flex align-items-center">
                                        <NavDropdown.Item as={Link} to="/teaching">Teaching</NavDropdown.Item>
                                    </NavDropdown> */}
                  <Link
                    to="/teaching"
                    className="text-uppercase mx-2 nav-custom-edits mx-4 mx-xl-2 my-2 my-lg-2 my-xxl-3 "
                    title="Teaching"
                  >
                    Teaching
                  </Link>
                  <Link
                    to="/news"
                    className="text-uppercase mx-2 nav-custom-edits mx-4 mx-xl-2 my-2 my-lg-2 my-xxl-3 "
                    title="News"
                  >
                    News
                  </Link>
                  <Link
                    to="https://dharmaideal.org/"
                    target="_blank"
                    className="text-uppercase mx-2  external-links mx-4 mx-xl-2 my-2 my-lg-2 my-xxl-3 "
                    title="Dharma Ideal Campaign"
                  >
                    Dharma Ideal Campaign
                  </Link>
                  <HashLink
                    to="/#btmc-events"
                    className="text-uppercase mx-2  nav-custom-edits mx-4 mx-xl-2 my-2 my-lg-2 my-xxl-3 "
                    title="BTMC Events"
                  >
                    BTMC Events
                  </HashLink>
                  <Link
                    to="https://dharmaideal.org/"
                    target="_blank"
                    className="text-uppercase mx-2  external-links mx-4 mx-xl-2 my-2 my-lg-2 my-xxl-3 d-block d-xl-none"
                    title="Kathmandu International Buddhist Events"
                  >
                    {" "}
                    Kathmandu International Buddhist Events
                  </Link>
                  <Link
                    to="/contact-us"
                    className="text-uppercase mx-2  nav-custom-edits mx-4 mx-xl-2 my-2 my-lg-2 my-xxl-3 d-block d-xl-none"
                    title="Contact Us"
                  >
                    Contact Us
                  </Link>
                  <Link to="/book-concert" className="ms-2">
                    <button className="donate-btn btn py-1 py-md-2 px-3 px-xxl-4 text-capitalize mx-3 my-2 my-lg-2 my-xxl-3 d-xl-none">
                      Charity Live Music Tickets
                    </button>
                  </Link>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  );
};

export default Header;
