import 'bootstrap/dist/css/bootstrap.min.css';
import "./ForgetPassword.css"
import { Header,Footer } from '../../Common';
const ForgetPassword=(props)=>{
    return(
        <>
        <Header links={props.links} />
        <div className="container ">
        <form >
              <div className="  row justify-content-center my-4">
                <div className=" forget_password col  col-md-6 col-sm-8 my-3 mx-3">
                    <h3>Forgot Password?</h3>
                    <p>Please enter the email to reset the password</p>
                   
                        <input  className="form-control" type="email" placeholder="Email"/>
                      
                       
                        <button type="submit" className="btn btn-danger btn_control mt-4  mb-2   float-end">Retrieve </button>
                </div>
                </div>
                    </form>
                </div>
                <Footer links={props.links} contacts={props.contacts} footerDescription={props.footerDescription} />
        
                </>
    );

}
export default ForgetPassword;