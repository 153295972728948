import './Login.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Header, Footer } from '../../Common';
import { CiMail, CiLock, CiUnlock } from "react-icons/ci";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useState } from 'react';
import { Api } from '../../Constants';
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../Reducer/userSlice';
import { clearRedirect, updateRoute } from "../Reducer/routesSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Login = (props) => {

    const user = useSelector(state => state.user)
    const route = useSelector(state => state.route)

    const [showPassword, setShowPassword] = useState(false);
    const [captcha, setCaptcha] = useState(null);
    const [captchaError, setCaptchaError] = useState(false);
    const [loginError, setLoginError] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm();

    function onChange(value) {
        // console.log("Captcha value:", value);
        setCaptcha(value);
    }

    const onSubmit = (data) => {
        if (captcha) {
            setCaptchaError(false);
            handleLogin(data);
        } else {
            setCaptchaError(true);
        }
    }

    const handleLogin = async (data) => {
        const res = await Api.postLogin({ email: data.email, password: data.password });
        console.log('res', res)
        if (res) {
            const { token, user_detail: user_detail } = res;
            localStorage.setItem("token", token);

            // Dispatch the login action to update the user state
            dispatch(login(user_detail));

            const redirectTo = route.currentRoute || '/'
            
            // Dispatch the clearRedirect action to reset the route state
            dispatch(clearRedirect());

            toast.success("Successfully Logged In! Redirecting...", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(() => {
                navigate(redirectTo)
            }, 1000)

        } else {
            setLoginError(true)
        }
    }

    return (

        <>
            <Header links={props.links} />

            <div className='container '>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='row  justify-content-center my-4'>
                        <div className='  col col-md-6 col-sm-8 my-3 '>
                            <div className='login '>
                                <h3>Login</h3>
                                {loginError && <p className="text-danger">Email or Password is incorrect</p>}
                                <div className='input-control' >
                                    <label htmlFor="email" className='label'>Email</label>
                                    <input
                                        {...register('email', { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
                                        type="text"
                                        className="form-control"
                                    />
                                    <CiMail className='icon' />

                                </div>

                                <div className='input-control'>
                                    <label htmlFor="password" className='label'>Password</label>
                                    <input
                                        {...register('password', { required: true })}
                                        type={showPassword ? "text" : "password"}
                                        className="form-control"
                                    />
                                    {!showPassword ? (
                                        <CiLock className='icon' onClick={() => setShowPassword(!showPassword)} />
                                    ) : (
                                        <CiUnlock className='icon' onClick={() => setShowPassword(!showPassword)} />
                                    )}
                                </div>

                                <div className="row">
                                    <div className="col-sm-8 offset-sm-4">
                                        {errors.password && <span className="text-danger">Password is required</span>}
                                    </div>
                                </div>

                                <div className='forget-set'>
                                    <Link to="/forget_password">Forget password?</Link>
                                </div>
                                <div className='g-recaptcha '>
                                    <ReCAPTCHA
                                        // sitekey="6LfFnjUfAAAAAH0tqwoJDI27iaF1VFCbDkN7F3Bn"
                                        sitekey="6LebMUIpAAAAAKp6UDsAamop6_5aUQanmr2bQAQ6"
                                        onChange={onChange}
                                        size="normal"
                                    />
                                </div>
                                {captchaError && <span className="text-danger">Captcha is required</span>}
                                <div className='button-control'>
                                    <button type='submit' className='btn btn-danger ' >Login</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>


            <Footer links={props.links} contacts={props.contacts} footerDescription={props.footerDescription} />
            <ToastContainer />


        </>
    );
}
export default Login;