import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom"
import { Api, Images } from "../../Constants";

import { ImFacebook } from "react-icons/im";
import { FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import { IoLogoTwitter } from "react-icons/io";

import './Footer.css';


const Footer = (props) => {


    return (
        <footer>
            <div className="footer  pb-2">
                <Container>
                    <div className="d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between mx-lg-2">
                        <div className="d-flex flex-column flex-lg-row">
                            {/* <div className=" col-lg-11 me-lg-5 text-center text-lg-start"> */}

                            {props.contacts.branchOffice !== null || props.contacts.branch_mobile_no !== null ? (
                                <div className="d-flex flex-column flex-lg-row">
                                    <div className="  col-lg-5 me-lg-5 text-center text-lg-start">
                                        <h5 className="mt-4">Contacts</h5>

                                        <div className="mt-3 mb-2 office-title">
                                            India Office:
                                        </div>
                                        <div className="mb-2 contact-info">
                                            {props.contacts.organization_name && (
                                                <>
                                                    <strong>{props.contacts.organization_name}</strong>
                                                    <br />
                                                </>

                                            )}
                                            <p dangerouslySetInnerHTML={{ __html: props.contacts.headOffice }}>
                                            </p>
                                        </div>
                                        {props.contacts.email && (
                                            <div className="mb-2">
                                                Email: {props.contacts.email}
                                            </div>
                                        )}
                                        {props.contacts.tel && (
                                            <div className="mb-2">
                                                Tel: {props.contacts.tel}
                                            </div>
                                        )}
                                        {props.contacts.mobile_no && (
                                            <div className="mb-lg-2">
                                                Mobile: {props.contacts.mobile_no}
                                            </div>
                                        )}

                                    </div>
                                    <div className=" col-lg-5 col-xxl-6 text-center text-lg-start pt-0 mt-lg-0 ">
                                        <h5 className="d-none d-lg-block">&nbsp;</h5>
                                        <h5 className="d-none d-lg-block">&nbsp;</h5>

                                        <div className="mt-3 mt-lg-1 mb-2 office-title">
                                            Branch Office:
                                        </div>
                                        <div className="mb-2 contact-info">
                                            {props.contacts.branchOffice}
                                        </div>
                                        {props.contacts.branch_mobile_no && (
                                            <div className="mb-2">
                                                Mobile: {props.contacts.branch_mobile_no}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className=" col-lg-12 text-center text-lg-start">
                                    <h5 className="mt-4">Contacts</h5>

                                    <div className="mt-3 mb-2 office-title">
                                        India Office:
                                    </div>
                                    <div className="mb-2 contact-info">
                                        {props.contacts.organization_name && (
                                            <>
                                                <strong>{props.contacts.organization_name}</strong>
                                                <br />
                                            </>

                                        )}
                                        <p dangerouslySetInnerHTML={{ __html: props.contacts.headOffice }}>
                                        </p>
                                    </div>
                                    {props.contacts.email && (
                                        <div className="mb-2">
                                            Email: {props.contacts.email}
                                        </div>
                                    )}
                                    {props.contacts.tel && (
                                        <div className="mb-2">
                                            Tel: {props.contacts.tel}
                                        </div>
                                    )}
                                    {props.contacts.mobile_no && (
                                        <div className="mb-lg-2">
                                            Mobile: {props.contacts.mobile_no}
                                        </div>
                                    )}

                                </div>
                            )}

                        </div>

                        <div className="text-center text-lg-start mt-lg-0 ">
                            <h5 className="mt-4">Social Media</h5>
                            <div className="row my-3">
                                <div className="col col-md-12 d-flex justify-content-center justify-content-lg-start">
                                    <a href={props.links.facebook} title={props.links.facebook} target="_blank" className="f-links"><ImFacebook className="f-icon" /></a>
                                    <a href={props.links.linkedin} title={props.links.linkedin} target="_blank" className="f-links"><FaLinkedinIn className="f-icon" /></a>
                                    <a href={`mailto:${props.contacts.email}`} title={props.contacts.email} target="_blank" className="f-links"><MdMailOutline className="f-icon" /></a>
                                    {/* <a href={props.links.twitter} title={props.links.twitter} className="f-links"><IoLogoTwitter className="f-icon" /></a> */}
                                    <a href={props.links.youtube} title={props.links.youtube} target="_blank" className="f-links"><FaYoutube className="f-icon" /></a>
                                </div>
                            </div>

                            <h5 className="mt-4">Powered by:</h5>
                            <div className="text-center text-lg-start">
                                <Link to='https://outlines-rnd.com/' target="_blank" className="outlines_link">
                                    <img src={Images.logooutlines} className="img-fluid" width={150} />
                                </Link>
                            </div>
                        </div>
                    </div>

                </Container>
                <hr style={{ borderColor: 'white' }} />
                <div className="text-center">
                    {props.footerDescription ? (
                        <>
                            <div dangerouslySetInnerHTML={{ __html: props.footerDescription }} />
                        </>
                    ) : (
                        <>
                            © BTMC Foundation
                        </>
                    )}

                </div>
            </div>
        </footer>
    )
}

export default Footer;