//Local

// const FILE_URL = 'http://127.0.0.1:8000/storage/uploads';
// const BASE_URL = "http://127.0.0.1:8000/api/v1";
// const IMAGE_URL = "https://127.0.0.1:8000";

// Live
// btmcfoundation.in
const FILE_URL = "https://api.btmcfoundation.in/storage/uploads";
const BASE_URL = "https://api.btmcfoundation.in/api/v1";
const IMAGE_URL = "https://api.btmcfoundation.in";

// btmcfoundation.org
// const FILE_URL = "https://api.btmcfoundation.org/storage/uploads";
// const BASE_URL = "https://api.btmcfoundation.org/api/v1";
// const IMAGE_URL = "https://api.btmcfoundation.org";

// const FILE_URL = 'https://192.168.1.84/storage/uploads';
// const BASE_URL = 'https://192.168.1.84/api/v1';
// const IMAGE_URL = "https://192.168.1.84";

//Event Registration
// btmcfoundation.in
const GET_EVENT_DETAIL_URL = "https://apievent.btmcfoundation.in/api_v2/";
// btmcfoundation.org
// const GET_EVENT_DETAIL_URL = "https://apievent.btmcfoundation.org/api_v2/";
// const REGISTER_BASE_URL = 'https://api.dharmaideal.org/api/v1/';

export default {
  FILE_URL,
  BASE_URL,
  IMAGE_URL,
  // REGISTER_BASE_URL,
  GET_EVENT_DETAIL_URL,
};
