import React, { useEffect } from 'react';
import AppRouter from './Routes/AppRouter';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

const App = () => {

  return (
    <>
      <AppRouter />
    </>
  )
};

export default App;
