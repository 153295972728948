import React, { useState, useEffect } from "react";
import { FaRegUser } from "react-icons/fa";
import { FaComments } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import { CgFormatSlash } from "react-icons/cg";
import { AiFillPlayCircle } from "react-icons/ai";

import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import './InternationalEvents.css'
import { Images, Api, HelperFunction } from "../../Constants";
import { Link } from "react-router-dom";

const InternationalEvents = () => {

    const [internationalEvents, setInternationalEvents] = useState([]);

    useEffect(() => {
        getInternationalEvents();
    }, []);


    const getInternationalEvents = async (id) => {
        const res = await Api.getInternationalEventsData(id);
        if (res.status) {
            console.log(res)
            setInternationalEvents(res.data);
        } else {
            setInternationalEvents([])
        }
    }


    const RenderChild = (props) => {
        const { slug: slug, file_image: file_image, title: event_name, description: description } = props.data;
        return (
            <div className="events-captions">
                <Link to={`/international-events/${slug}`} state={props.data} className="text-decoration-none ">
                    <img
                        src={`${HelperFunction.FILE_URL}/event/${file_image}`}
                        alt="dharma" className="img-fluid w-100 i-links" style={{ backgroundColor: '#970d0d' }} />
                </Link>

                <div className="events-label my-3 mt-4 mx-3">
                    <div className="row mb-2">
                        {/* <div className="col">
                    <div className="d-flex justify-content-start align-items-center">
                        <FaRegUser color="#db4c54"/>
                        <div className="mx-2">Admin</div>
                        <FaComments color="#db4c54"className="ms-3"/>
                        <div className="mx-2">Comments</div>
                    </div>
                </div> */}
                        <div className="col">
                            <div className="d-flex justify-content-start align-items-center">

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Link to={`/international-events/${slug}`} state={props.data} className="text-decoration-none i-links">

                            <div className="event-name text-decoration-none i-links mt-1 mb-3">
                                {event_name}
                            </div>
                        </Link>
                    </div>
                </div>

                <hr />
                <div className="more-section mx-3 pb-3">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex justify-content-start align-items-center">
                                <div className="read-more">Read More</div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-end align-items-center me-lg-2">
                                <Link to={`/international-events/${slug}`} state={props.data} className="text-decoration-none text-dark i-links">
                                    <div><BsArrowRight className="arrow i-links" /></div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            {internationalEvents.length > 0 && (
                <>
                    <div className="container">
                        {/* <div className="mx-2 mx-lg-5 my-xl-2 py-3"> */}
                        <div className="mx-2 mx-lg-2">
                            <div className="internationalEvents-header text-center text-lg-start">
                                <div className="mt-4 d-flex align-items-end justify-content-center justify-content-lg-start"><CgFormatSlash color="#db4c54" className="slash" style={{ marginLeft: '-8px' }} /><CgFormatSlash color="#db4c54" className="slash" style={{ marginLeft: '-26px' }} /><CgFormatSlash color="#db4c54" className="slash" style={{ marginLeft: '-26px' }} /><span>Events</span></div>
                                <h1 className="internationalEvents-title text-capitalize my-4">International Events</h1>
                            </div>
                            <div>

                                <div dangerouslySetInnerHTML={{ __html: internationalEvents?.[0].description }} className='static-content' style={{ textAlign: "justify" }} />


                                <div className='desktop-view'>
                                    <div className='pt-3 pt-lg-4'>
                                        <Swiper
                                            slidesPerView={3}
                                            spaceBetween={30}
                                            slidesPerGroup={1}
                                            loop={false}
                                            navigation={true}
                                            freeMode={true}
                                            className="swiper-h px-0"
                                        >
                                            {/* {internationalEvents.map((elem, index) => (
                                                <div key={index}>
                                                    <SwiperSlide key={index} >
                                                        <div className=''>
                                                            <RenderChild key={elem.id} data={elem} />
                                                        </div>
                                                    </SwiperSlide>
                                                </div>
                                            ))} */}
                                            <SwiperSlide >
                                                <div className='events-captions px-3 text-center d-flex align-items-center text-dark' style={{height: '7rem'}}>
                                                    16 day Karpo Chhyage Yungne Mahayaan Upashodh Fasting
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide >
                                                <div className='events-captions px-3 text-center d-flex align-items-center text-dark' style={{height: '7rem'}}>
                                                    1 day World Peace  fire puja and empowerment of Mahakarunami
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide >
                                                <div className='events-captions px-3 text-center d-flex align-items-center text-dark' style={{height: '7rem'}}>
                                                    3 days International Dharma Ideal Conference
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>

                                <div className='row mobile-view'>

                                    {/* {internationalEvents.map((elem, index) => (
                                        <div key={index} className='col-12 col-md-6 mb-4'>
                                            <RenderChild key={elem.id} data={elem} />
                                        </div>
                                    )
                                    )} */}
                                    <div className='col-12 col-md-6 mb-4 events-captions px-3 text-center d-flex align-items-center text-dark' style={{height: '7rem'}}>
                                        16 day Karpo Chhyage Yungne Mahayaan Upashodh Fasting
                                    </div>
                                    <div className='col-12 col-md-6 mb-4 events-captions px-3 text-center d-flex align-items-center text-dark' style={{height: '7rem'}}>
                                        1 day World Peace  fire puja and empowerment of Mahakarunami
                                    </div>
                                    <div className='col-12 col-md-6 mb-4 events-captions px-3 text-center d-flex align-items-center text-dark' style={{height: '7rem'}}>
                                        3 days International Dharma Ideal Conference
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="icon3">
                        <img src={Images.icon3} alt="Dharma Idol Logo" className="img-fluid" />
                    </div>
                </>
            )}
        </>
    )
}

export default InternationalEvents;