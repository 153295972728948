import React, { useState, useEffect } from "react";
import {
  Header,
  Footer,
  Banner,
  InternationalEvents,
  Fancybox,
} from "../../Common";
import { Api, HelperFunction, Images } from "../../Constants";
import { Container, Form, Tab, Tabs, Spinner, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CgFormatSlash } from "react-icons/cg";
import { AiFillPlayCircle } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";

import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import moment from "moment";
import "./Homepage.css";

const scheduelData = [
  {
    id: 1,
    month: "January",
    date: [
      {
        id: 1,
        date: "01",
        title: "Lorem ipsum dolor",
        content:
          "January ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 2,
        date: "02",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 3,
        date: "03",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 4,
        date: "04",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
    ],
  },
  {
    id: 2,
    month: "February",
    date: [
      {
        id: 1,
        date: "01",
        title: "Lorem ipsum dolor",
        content:
          "February ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 2,
        date: "02",
        title: "Lorem ipsum dolor",
        content:
          "February ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 3,
        date: "03",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 4,
        date: "04",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
    ],
  },
  {
    id: 3,
    month: "March",
    date: [
      {
        id: 1,
        date: "01",
        title: "Lorem ipsum dolor",
        content:
          "March ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 2,
        date: "02",
        title: "Lorem ipsum dolor",
        content:
          "March ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 3,
        date: "03",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 4,
        date: "04",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
    ],
  },
  {
    id: 4,
    month: "April",
    date: [
      {
        id: 1,
        date: "01",
        title: "Lorem ipsum dolor",
        content:
          "April ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 2,
        date: "02",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 3,
        date: "03",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 4,
        date: "04",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
    ],
  },
  {
    id: 5,
    month: "May",
    date: [
      {
        id: 1,
        date: "01",
        title: "Lorem ipsum dolor",
        content:
          "May ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 2,
        date: "02",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 3,
        date: "03",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 4,
        date: "04",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
    ],
  },
  {
    id: 6,
    month: "June",
    date: [
      {
        id: 1,
        date: "01",
        title: "Lorem ipsum dolor",
        content:
          "June ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 2,
        date: "02",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 3,
        date: "03",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 4,
        date: "04",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
    ],
  },
  {
    id: 7,
    month: "July",
    date: [
      {
        id: 1,
        date: "01",
        title: "Lorem ipsum dolor",
        content:
          "July ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 2,
        date: "02",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 3,
        date: "03",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 4,
        date: "04",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
    ],
  },
  {
    id: 8,
    month: "August",
    date: [
      {
        id: 1,
        date: "01",
        title: "Lorem ipsum dolor",
        content:
          "August ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      {
        id: 2,
        date: "02",
        title: "Lorem ipsum dolor",
        content:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.",
      },
      // {
      //     id: 3,
      //     date: '03',
      //     title: 'Lorem ipsum dolor',
      //     content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.'
      // },
      // {
      //     id: 4,
      //     date: '04',
      //     title: 'Lorem ipsum dolor',
      //     content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl. Sed euismod, diam id rhoncus aliquam, nisl nunc aliquet enim, vitae aliquam nisl nunc auctor nisl.'
      // },
    ],
  },
];

const events = [
  {
    id: 1,
    title: "Lorem ipsum dolor sit amet, consecteteur adipiscing elit",
    image: Images.hqdefault,
  },
  {
    id: 2,
    title: "Lorem ipsum dolor sit amet, consecteteur adipiscing elit",
    image: Images.hqdefault,
  },
  {
    id: 3,
    title: "Lorem ipsum dolor sit amet, consecteteur adipiscing elit",
    image: Images.hqdefault,
  },
];

const news = [
  {
    id: 1,
    thumbnail: Images.hqdefault,
  },
  {
    id: 2,
    thumbnail: Images.dharmalogo1,
  },
  {
    id: 3,
    thumbnail: Images.fy,
  },
  {
    id: 4,
    thumbnail: Images.bhodi_satto_1,
  },
  {
    id: 5,
    thumbnail: Images.hqdefault,
  },
  {
    id: 6,
    thumbnail: Images.dharmalogo1,
  },
  // {
  //     id: 7,
  //     thumbnail: Images.fy,
  // },
  // {
  //     id: 8,
  //     thumbnail: Images.bhodi_satto_1,
  // }
];
const HomePage = (props) => {
  const [staticpageData, setStaticpageData] = useState(undefined);
  const [bannerPictures, setBannerPictures] = useState([]);
  const [concert, setConcert] = useState([]);
  const [aboutusDescription1920screen, setAboutusDescription1920screen] =
    useState();
  const [aboutusDescription1360screen, setAboutusDescription1360screen] =
    useState();
  const [aboutusDescription1080screen, setAboutusDescription1080screen] =
    useState();
  const [latestNews, setLatestNews] = useState(news);
  const [schedule, setSchedule] = useState(scheduelData);
  const [activeTab, setActiveTab] = useState("january");
  //Annoucements
  const [campaign, setCampaign] = useState({});
  const [campaignModal, setCampaignModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    fetchCampaign();
    getStaticData();
    getBannerData();
    getProgramList();
  }, []);

  const getBannerData = async () => {
    const res = await Api.getBannerPictures();
    res.image && setBannerPictures(res.image);
  };

  const getProgramList = async () => {
    const res = await Api.getProgrammes();
    if (!res) return;
    setConcert(res?.event_details);
  };

  const getStaticData = async () => {
    const res = await Api.getStaticPage("/page/welcome-message");
    if (!res.page) return;
    setStaticpageData(res.page);
    var temp1080Description = res.page.description.substring(0, 470) + "...";
    var temp1360Description = res.page.description.substring(0, 600) + "...";
    var temp1920Description = res.page.description.substring(0, 1020) + "...";

    setAboutusDescription1920screen(temp1920Description);
    setAboutusDescription1360screen(temp1360Description);
    setAboutusDescription1080screen(temp1080Description);
  };

  const handleCampaignModal = () => {
    const hideCampaign = sessionStorage.getItem("campaign_hide");
    if (hideCampaign === "true") return;
    setCampaignModal(!campaignModal);
    sessionStorage.setItem("campaign_hide", "true");
  };

  const openCampaignOnLoad = () => {
    const hideCampaign = sessionStorage.getItem("campaign_hide");
    if (hideCampaign === "true") return;
    setCampaignModal(!campaignModal);
  };

  const fetchCampaign = async () => {
    const response = await Api.getCampaign();
    if (response?.data) {
      setCampaign(response?.data[0]);
      openCampaignOnLoad();
    } else {
      return;
    }
  };

  return (
    <>
      <Header links={props.links} />
      <Banner bannerPictures={bannerPictures} />

      {/* About Us Section */}
      <div className=" about-section my-3 my-lg-0" id="dharma-ideal-campaign">
        <div className="container">
          <div className="description row text-center text-lg-start mx-2 mx-lg-0 py-xl-5 py-3">
            <div className="order-2 order-lg-1 col col-12 col-lg-5 aboutImage-container mt-3 mt-lg-0 pe-4 pe-xl-5">
              <div className="d-flex align-items-end  justify-content-lg-start d-none d-lg-block">
                &nbsp;
              </div>
              <h1 className="about-title text-capitalize my-4 my-lg-3 my-xxl-4 d-none d-lg-block">
                &nbsp;
              </h1>
              <div className="dharma-a-new" title="">
                <Fancybox
                  options={{
                    Carousel: {
                      infinite: false,
                    },
                  }}
                >
                  {/* <a href="https://www.youtube.com/watch?v=aVFBzz70_6g" data-fancybox="video-gallery"> */}
                  {/* <img alt="" className="img-fluid mb-lg-4 mt-2 " src={Images.dharmaguru2} /> */}
                  {staticpageData?.main_image !== null ? (
                    <img
                      src={`${HelperFunction.FILE_URL}/main_image/${staticpageData?.main_image}`}
                      alt="btmc"
                      className="img-fluid w-100"
                    />
                  ) : (
                    <img
                      src={Images.dharmaguru2}
                      alt="dharma"
                      className="img-fluid w-100"
                    />
                  )}
                  {/* <AiFillPlayCircle className="fs-2 play" color="yellow" /> */}
                  {/* </a> */}
                </Fancybox>
              </div>
            </div>
            <div className="order-lg-2 col col-lg-7 ps-0 dharma-about-container mt-lg-4">
              {/* <div className="d-flex align-items-end justify-content-center justify-content-lg-start"><CgFormatSlash color="#970d0d" className="slash" style={{ marginLeft: '-8px' }} /><CgFormatSlash color="#970d0d" className="slash" style={{ marginLeft: '-26px' }} /><CgFormatSlash color="#970d0d" className="slash" style={{ marginLeft: '-26px' }} /><span>About Us</span></div> */}
              <h1 className="about-title text-capitalize my-4 my-lg-3 my-xxl-4">
                {staticpageData?.name}
              </h1>
              <div>
                <div className="about-content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: aboutusDescription1080screen,
                    }}
                    className="static-content d-block d-md-none"
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: aboutusDescription1080screen,
                    }}
                    className="static-content d-none d-md-block d-xl-none"
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: aboutusDescription1360screen,
                    }}
                    className="static-content d-none d-xl-block d-xxl-none"
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: aboutusDescription1920screen,
                    }}
                    className="static-content d-none d-xxl-block"
                  />

                  <div className="text-lg-end mt-3">
                    <Link to={staticpageData?.page_slug}>
                      <button className="showmore-btn btn my-4 py-2 px-4">
                        Read More
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="icon1">
              <img
                src={Images.icon2}
                alt="Dharma Ideal Logo"
                className="img-fluid mt-lg-4"
              />
            </div>
          </div>
        </div>
      </div>

      {/* concert section */}
      <section id="book-concert-section">
        <div className="concert-section" id="btmc-events">
          <div className="row mx-0">
            <div className="title text-center mb-3 mb-xl-5">
              <h1 className="about-title text-capitalize mt-5 mb-lg-3">
                Charity Live Music Show
              </h1>
              <div className="container">
                {concert && (
                  <div className="row">
                    <Swiper
                      slidesPerView={2}
                      spaceBetween={30}
                      navigation={true}
                      className="mySwiper mt-4 mb-4 px-xl-4 desktop-view"
                    >
                      modules={[Navigation]}
                      {concert.map((elem) => (
                        <SwiperSlide key={elem.id}>
                          <Link
                            to="/book-concert/"
                            state={{
                              id: elem.id,
                              name: elem.name,
                              venue: elem.venue,
                              date: elem.date_from,
                            }}
                          >
                            <img
                              // src={`${HelperFunction.FILE_EVENT_DETAIL_URL}/image/${elem.login_logo}`}
                              src={elem.login_logo}
                              alt="BTMC_concert"
                              className="img-fluid w-100"
                              title="Book Now"
                            />
                          </Link>

                          <div className="concert-note mt-4 table-responsive">
                            <table className="w-100 ">
                              <thead>
                                <tr>
                                  <th colSpan={2} className=" px-3">
                                    {elem.name}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="concertinfo-title text-start px-3">
                                    Date
                                  </td>
                                  <td className="text-start px-3 text-capitalize">
                                    {" "}
                                    {moment(elem.date_from).format(
                                      "MMM DD, YYYY"
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="concertinfo-title text-start px-3">
                                    Time
                                  </td>
                                  <td className="text-start px-3">
                                    {" "}
                                    {moment(elem.time_from, "HH:mm").format(
                                      "h:mm A"
                                    )}{" "}
                                    -{" "}
                                    {moment(elem.time_to, "HH:mm").format(
                                      "h:mm A"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="concertinfo-title text-start px-3">
                                    Venue{" "}
                                  </td>
                                  <td className="text-start px-3 text-capitalize">
                                    {" "}
                                    {elem.venue}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="concertinfo-title text-start px-3">
                                    Price{" "}
                                  </td>
                                  <td className="text-start px-3">
                                    {elem.registration_category.map(
                                      (priceElem, index) => (
                                        <div key={index}>
                                          <strong className="concertinfo-price ">
                                            {priceElem.type}:
                                          </strong>{" "}
                                          {priceElem.currency}. {priceElem.rate}
                                          <br />
                                        </div>
                                      )
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className="bookbtn mt-4 d-flex justify-content-end">
                            {/* <Link to="/book-concert/" state={{ id: elem.id }}>
                            <button className="donate-btn btn py-1 px-3 px-xxl-4 text-capitalize">
                              Book Now
                            </button>
                          </Link> */}
                            {elem.payment_url ? (
                              <Link
                                to={`${elem.payment_url}`}
                                state={{
                                  id: elem.id,
                                  // name: elem.name,
                                  // venue: elem.venue,
                                  // date: elem.date_from,
                                }}
                              >
                                <button className="donate-btn btn py-1 px-3 px-xxl-4 text-capitalize">
                                  Book Now
                                </button>
                              </Link>
                            ) : (
                              <Link
                                to="/book-concert/"
                                state={{
                                  id: elem.id,
                                  name: elem.name,
                                  venue: elem.venue,
                                  date: elem.date_from,
                                }}
                              >
                                <button className="donate-btn btn py-1 px-3 px-xxl-4 text-capitalize">
                                  Book Now
                                </button>
                              </Link>
                            )}
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>

                    {concert.map((elem, index) => (
                      <div
                        key={index}
                        className="col-12 col-md-6 mt-4 mb-4 px-xl-4 mobile-view"
                      >
                        <Link
                          to="/book-concert/"
                          state={{
                            id: elem.id,
                            name: elem.name,
                            venue: elem.venue,
                            date: elem.date_from,
                          }}
                        >
                          <img
                            // src={`${HelperFunction.FILE_EVENT_DETAIL_URL}/image/${elem.login_logo}`}
                            src={elem.login_logo}
                            alt="BTMC_concert"
                            className="img-fluid w-100"
                            title="Book Now"
                          />
                        </Link>

                        <div className="concert-note mt-4 table-responsive">
                          <table className="w-100 ">
                            <thead>
                              <tr>
                                <th colSpan={2} className="px-3">
                                  {elem.name}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="concertinfo-title text-start px-3">
                                  Date
                                </td>
                                <td className="text-start px-3">
                                  {elem.date_from}{" "}
                                </td>
                              </tr>
                              <tr>
                                <td className="concertinfo-title text-start px-3">
                                  Time
                                </td>
                                <td className="text-start px-3">
                                  {" "}
                                  {elem.time_from} - {elem.time_to}
                                </td>
                              </tr>
                              <tr>
                                <td className="concertinfo-title text-start px-3">
                                  Venue{" "}
                                </td>
                                <td className="text-start px-3">
                                  {" "}
                                  {elem.venue}{" "}
                                </td>
                              </tr>
                              <tr>
                                <td className="concertinfo-title text-start px-3">
                                  Price{" "}
                                </td>
                                <td className="text-start px-3">
                                  {/* <strong className="concertinfo-price ">
                                  VVIP:
                                </strong>{" "}
                                Rs. 15,000.00
                                <br />
                                <strong className="concertinfo-price ">
                                  VIP:
                                </strong>{" "}
                                Rs. 10,000.00 <br />
                                <strong className="concertinfo-price ">
                                  Ordinary:
                                </strong>{" "}
                                Rs.5,000.00 */}
                                  {elem.registration_category.map(
                                    (priceElem, index) => (
                                      <div key={index}>
                                        <strong className="concertinfo-price ">
                                          {priceElem.type}:
                                        </strong>{" "}
                                        {priceElem.currency}. {priceElem.rate}
                                        <br />
                                      </div>
                                    )
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="bookbtn mt-4 d-flex justify-content-end">
                          {/* <Link to='/book-concert/' state={{ id: elem.id }} >
                                                    <button className='donate-btn btn py-1 px-3 px-xxl-4 text-capitalize'>
                                                        Book Now
                                                    </button>
                                                </Link> */}
                          {elem.payment_url ? (
                            <Link
                              to={`${elem.payment_url}`}
                              state={{ id: elem.id }}
                            >
                              <button className="donate-btn btn py-1 px-3 px-xxl-4 text-capitalize">
                                Book Now
                              </button>
                            </Link>
                          ) : (
                            <Link
                              to="/book-concert/"
                              state={{
                                id: elem.id,
                                name: elem.name,
                                venue: elem.venue,
                                date: elem.date_from,
                              }}
                            >
                              <button className="donate-btn btn py-1 px-3 px-xxl-4 text-capitalize">
                                Book Now
                              </button>
                            </Link>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="news-section d-none d-xl-block" id="latest-news">
                <div className="row mx-0">
                    <div className="col-lg-4 guru px-0">
                        <img src={Images.guru} alt="Dharma Ideal Campaign" className="img-fluid mt-4" />
                    </div>
                    <div className="col mx-2 mx-lg-3 px-lg-3 px-xxl-5 mt-xl-2 pt-xl-3 mt-xxl-5 ">
                        <div className="d-flex align-items-end justify-content-center justify-content-lg-start"><CgFormatSlash color="#970d0d" className="slash" style={{ marginLeft: '-8px' }} /><CgFormatSlash color="#970d0d" className="slash" style={{ marginLeft: '-26px' }} /><CgFormatSlash color="#970d0d" className="slash" style={{ marginLeft: '-26px' }} /><span>Latest News</span></div>
                        <h1 className="news-title text-capitalize my-4">Dharma Ideal International <br /> Campaign News Updates</h1>

                    </div>
                </div>
                <div className="news-sliders">
                    <div className="mx-0 d-flex justify-content-center">
                        <div className="col-lg-12">
                            <Swiper
                                slidesPerView={4}
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                            >
                                {latestNews.map((elem) => (
                                    <SwiperSlide key={elem.id}  >
                                        <div className="media-div" title="View News">
                                            <picture>
                                                <img src={elem.thumbnail} alt="dharma" className="pictures img-fluid w-100" />
                                            </picture>
                                           
                                        </div>


                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div> */}

      {/* International Campaign News Update Section */}
      {/* <div className=" news-section d-xl-none" id="latest-news">
                <div className="container">
                    <div className="mx-2 mx-lg-5 my-xl-5 py-3">
                        <div className="internationalEvents-header text-center text-lg-start">
                            <div className="mt-5 d-flex align-items-end justify-content-center justify-content-lg-start"><CgFormatSlash color="#970d0d" className="slash" style={{ marginLeft: '-8px' }} /><CgFormatSlash color="#970d0d" className="slash" style={{ marginLeft: '-26px' }} /><CgFormatSlash color="#970d0d" className="slash" style={{ marginLeft: '-26px' }} /><span>Latest News</span></div>
                            <h1 className="schedule-title text-capitalize my-4">Dharma Ideal International Campaign News Updates</h1>
                        </div>
                        <div>

                            <div className='row news-mobile-view'>

                                <Swiper
                                    // slidesPerView={4}
                                    breakpoints={{
                                        567: { slidesPerView: 2 },
                                        991: { slidesPerView: 3 },
                                        1400: { slidesPerView: 4 },
                                    }}
                                    spaceBetween={30}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper pb-3"
                                >
                                    {latestNews.map((elem) => (
                                        <SwiperSlide key={elem.id}  >
                                            <div className="media-div" title="View News">
                                                <picture>
                                                    <img src={elem.thumbnail} alt="dharma" className="pictures img-fluid w-100" />
                                                </picture>
                                                
                                            </div>


                                        </SwiperSlide>
                                    ))}
                                </Swiper>

                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

      {/* Projects Section */}

      {/* <div className=" projects-section" id="dharma-projects">
                <div className="container">
                    <div className="mx-2 mx-lg-2 my-xl-4 py-3">
                        <div className="projetcs-header text-center">
                            <div className="mt-5 d-flex flex-row align-items-end justify-content-center"><CgFormatSlash color="#970d0d" className="slash" style={{ marginLeft: '-8px' }} /><CgFormatSlash color="#970d0d" className="slash" style={{ marginLeft: '-26px' }} /><CgFormatSlash color="#970d0d" className="slash" style={{ marginLeft: '-26px' }} /><span className="mt-1">Projects</span></div>
                            <h1 className="projects-title text-capitalize my-4">Dharma Projects</h1>
                        </div>

                        <div className='projects-content row mb-5'>
                            <div className="col-12 col-lg-4 ms-lg-5">
                                <img src={Images.dharmalogo1} alt="Dharma Idol Logo" className="img-fluid mt-lg-4 w-100 project-img" />
                            </div>
                            <div className="col mt-3 mt-lg-4 mx-3 mx-lg-0 ms-lg-5 px-0 ">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="ps-4 pe-3">
                                                    <img src={Images.tv} alt="Dharma Idol Logo" className="img-fluid tvlogo" />
                                                    <div className="projects-content-title my-2">Sponsor Dharma TV programs</div>
                                                    <div className="projects-content-description">Dharma Television is established with the pure objective of benefiting all sentient</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="ps-4 pe-3">
                                                    <img src={Images.tv} alt="Dharma Idol Logo" className="img-fluid tvlogo" />
                                                    <div className="projects-content-title my-2">Sponsor Dharma TV programs</div>
                                                    <div className="projects-content-description">Dharma Television is established with the pure objective of benefiting all sentient</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="ps-4 pe-3">
                                                    <img src={Images.tv} alt="Dharma Idol Logo" className="img-fluid tvlogo" />
                                                    <div className="projects-content-title my-2">Sponsor Dharma TV programs</div>
                                                    <div className="projects-content-description">Dharma Television is established with the pure objective of benefiting all sentient</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="ps-4 pe-3">
                                                    <img src={Images.tv} alt="Dharma Idol Logo" className="img-fluid tvlogo" />
                                                    <div className="projects-content-title my-2">Sponsor Dharma TV programs</div>
                                                    <div className="projects-content-description">Dharma Television is established with the pure objective of benefiting all sentient</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="icon4">
                    <img src={Images.icon4} alt="Dharma Idol Logo" className="img-fluid" />
                </div>
            </div> */}
      {/* Schedule Section */}
      {/* {scheduelData.length > 0 && (
                <div className="schedule-section" id="schedule">
                    <div className="container">
                        <div className="mx-2 mx-lg-2 py-3 ">
                            <div className="schedule-header text-center">
                                <div className="mt-5 d-flex flex-row align-items-end justify-content-center"><CgFormatSlash color="#fff" className="slash" style={{ marginLeft: '-8px' }} /><CgFormatSlash color="#fff" className="slash" style={{ marginLeft: '-26px' }} /><CgFormatSlash color="#fff" className="slash" style={{ marginLeft: '-26px' }} /><span className="mt-1">Schedule</span></div>
                                <h1 className="schedule-title text-capitalize my-4">Schedule</h1>
                            </div>
                            <div>
                                <Tabs
                                    defaultActiveKey={activeTab}
                                    onSelect={(k) => setActiveTab(k)}
                                    className="mb-3 justify-content-start"
                                >
                                    {schedule.map((item, index) => (
                                        <Tab eventKey={item.month.toLowerCase()} title={item.month} key={index}>
                                            <div className="schedule-content my-3">
                                                {item.date.map((date, index) => (
                                                    <div className="schedule-date row " key={index}>
                                                        <div className="col-3 col-md-2 col-xl-1 text-center date ">
                                                            <h2 className="mt-3 mt-lg-4">{date.date}</h2>
                                                        </div>
                                                        <div className="col-9 col-md-9 col-lg-10 ms-xl-4 ps-lg-3 mt-3 mt-lg-4">
                                                            <div className="title">{date.title}</div>
                                                            <div className="content my-1">{date.content}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Tab>
                                    ))}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            )} */}

      {/* International Events */}
      {/* <div className=" internationalEvents-section" id="international-events">

                <div className="my-lg-5">
                    <InternationalEvents />
                    <div className="container ">
                        <Link to={'/international-events'} className="text-decoration-none">
                        <div className="pb-4 pb-lg-4 mx-2 mx-lg-5 text-end pt-1 pb-lg-2"> <span className="internationalview-btn">View All <BsArrowRight className="arrow i-links" /></span> </div>
                        </Link>
                    </div>
                </div>
            </div> */}
      <Modal
        show={campaignModal}
        dialogClassName={"campaign-modal"}
        onHide={handleCampaignModal}
        centered
      >
        <Modal.Body>
          {campaign.type === "image" && (
            <div className="campaign">
              <img
                src={Images.close}
                onClick={handleCampaignModal}
                className="campaign-close"
              />
              <picture className={loading ? "img-loading" : ""}>
                <source
                  media="(max-width:414px)"
                  srcSet={`${HelperFunction.FILE_URL}/mobile_image/${campaign.mobile_image}`}
                />
                <img
                  onLoad={handleLoad}
                  src={`${HelperFunction.FILE_URL}/main_image/${campaign.main_image}`}
                  alt="btmcFoundation"
                  className="img-fluid w-100"
                />
              </picture>
            </div>
          )}
          {campaign.type === "youtube" && (
            <div>
              <img
                src={Images.close}
                onClick={handleCampaignModal}
                className="campaign-close"
              />
              <iframe
                className="w-100"
                style={{ height: "60vh" }}
                src={campaign.utube_link}
              ></iframe>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Footer
        links={props.links}
        contacts={props.contacts}
        footerDescription={props.footerDescription}
      />
    </>
  );
};

export default HomePage;
