import logo from "../Images/dharma_idol_logo.png";
import logo1 from "../Images/dharma_idol_logo1.png";
import logo2 from "../Images/1.png";
import banner1 from "../Images/web-banner-01.jpg";
import banner2 from "../Images/web-banner-02.jpg";
import MobileBanner from "../Images/Mobile-web-banner.jpg";
import dharmalogo from "../Images/logo_of_dharma_television_(2).jpg";
import dharmalogo1 from "../Images/logo333.png";
import icon1 from "../Images/icon1.png";
import icon2 from "../Images/icon2.png";
import Meditation from "../Images/Meditation.jpg";
import hqdefault from "../Images/hqdefault.jpg";
import fy from "../Images/fy.jpg";
import bhodi_satto_1 from "../Images/bhodi_satto_1.jpg";
import icon3 from "../Images/icon3.png";
import icon4 from "../Images/icon4.png";
import tv from "../Images/tv.png";
import guru from "../Images/photo_2023-10-30_13-41-34.png";
import khalti from "../Images/khalti-logo2.png";
import esewa from "../Images/2-eSewa-logo.png";
import cardPayment from "../Images/Card-payment4.png";
import dharmaguru1 from "../Images/dharmaguru1.png";
import dharmaguru2 from "../Images/dharmaguru2.png";
import logooutlines from "../Images/Logoout-01.png";
import btmclogo from "../Images/BTMC-Foundationlogo.png";
import btmclogoindia from "../Images/BTMC-FoundationIndia-04.png";
import btmcbanner from "../Images/btmcbanner.png";
import btmcmobile from "../Images/btmcmobile.png";
import concert from "../Images/concert.png";
import gantok from "../Images/gantok.jpg";
import siliguri from "../Images/siliguri.jpg";
import close from "../Images/close.svg";
import qr1 from "../Images/qr1.png";
import qr2 from "../Images/qr2.png";
import qr3 from "../Images/qr3.png";

const Images = {
  logo,
  logo1,
  logo2,
  banner1,
  banner2,
  MobileBanner,
  dharmalogo,
  dharmalogo1,
  icon1,
  icon2,
  Meditation,
  hqdefault,
  fy,
  bhodi_satto_1,
  icon3,
  icon4,
  tv,
  guru,
  khalti,
  esewa,
  cardPayment,
  dharmaguru1,
  dharmaguru2,
  logooutlines,
  btmclogo,
  btmcbanner,
  btmcmobile,
  concert,
  btmclogoindia,
  gantok,
  siliguri,
  close,
  qr1,
  qr2,
  qr3,
};

export default Images;
