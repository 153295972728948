import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentRoute: null,
  redirectToRoute: false,
};

export const routesSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    updateRoute: (state, action) => {
      state.redirectToRoute = true;
      state.currentRoute = action.payload;
    },
    clearRedirect: (state) => {
      return initialState;

    },
  },
});

export const { updateRoute, clearRedirect } = routesSlice.actions;

export const routeReducer = routesSlice.reducer;
